<!-- Start template when not logged in -->
<template>
    <div class="app">
      <header>
        <StartNavbar class="startNavBar" />
      </header>    
      <main role="main">
          <router-view />
      </main>
      <footer>
        <PageFooter />
      </footer>
    </div>
</template>

<script>
import StartNavbar from "../common/StartNavbar.vue";
import PageFooter from "../common/PageFooter.vue";

export default {
  components: {
    StartNavbar,
    PageFooter
  },
};
</script>

<style>
@import '../../styles/main.scss';

</style>