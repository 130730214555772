<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        // Apply the user's big font preference
        const isBigFont = localStorage.getItem("bigFont") === "true";
        document.body.classList.toggle("big-font", isBigFont);

        // Apply the user's dark mode preference
        const isDarkMode = localStorage.getItem("darkMode") === "true";
        document.body.classList.toggle("dark-mode", isDarkMode)
    },
}
</script>

<style></style>
