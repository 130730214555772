<template>
    <!-- Request Password Reset Container-->
    <div class="start-container ">

        <!-- Container title -->
        <h3>Request Password Reset</h3>

        <!-- Error banner if there is an error message  -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <!-- Success message if reset email sent -->
        <div v-if="successMessage" class="success-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ successMessage }}
        </div>

        <!-- Email field -->
        <h4>Email Address</h4>
        <input v-model="email" placeholder="Enter your account's email" aria-label="Enter your email" @keydown.enter="sendResetEmail()"/>

        <div class="verification-buttons">

            <!-- Send reset password email button -->
            <button class="primary-button" @click="sendResetEmail" aria-label="Send Reset Password Email">
                Reset Password
            </button>

            <!-- Back to Login button -->
            <button class="secondary-button" @click="loginNavigation" role="link" aria-label="Link to Login Page">
                Back to Login
            </button>

        </div>

    </div>

</template>

<script>
import axiosClient from '../../axiosClient';

export default {
    name: "request-password-reset-page",
    data() {
        return {
            errorMessage: "",
            successMessage: "",

            email: "",
        };
    },
    methods: {
        async sendResetEmail() {
            try {
                // Clear messages
                this.errorMessage = ""
                this.successMessage = ""

                // If no email, return
                if (!this.email) {
                    this.errorMessage = "Please enter your email."
                }

                // Call the API to send the password reset email
                const res = await axiosClient.post('/users/reset_pwd', {
                    "email": this.email
                })

                if (res.data.emailSent) {
                    // Clear error and email field
                    this.errorMessage = "";
                    this.email = ""

                    // Show success message
                    this.successMessage = "Password reset link sent. Please check your email";

                } else {
                    // Clear success message and email field
                    this.successMessage = "";
                    this.email = ""

                    // Show error message
                    this.errorMessage = "There was an error sending the password reset email. Please try again later."
                }

            } catch (error) {

                if (error.response && error.response.status === 400) {
                    // Fetch BE error message
                    this.backendError = error.response.data.message;

                    if (this.backendError.includes("Account does not exist")) {
                        // If the error is the account not existing
                        this.errorMessage = "Account does not exist."
                    }

                    else {
                        // Generic error message
                        this.errorMessage = "There was an error resending the password reset email. Please try again later."
                    }

                } else {
                    // Generic error message for other issues
                    this.errorMessage = "There was an error sending the password reset email. Please try again later.";
                }

            }
        },
        loginNavigation() {

            // Back to login button
            this.$router.push('/')

        }
    },
};
</script>