<template>
    <div class="profile-container preferences">

        <!-- Scrren Title and Information banners -->
        <div class="profile-screen-top-section preferences">
            <h3>Preferences</h3>

            <div class="information-banners preferences">
                <!-- Success banner -->
                <div v-if="successMessage" class="success-banner preferences" aria-live="polite">
                    <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                    {{ successMessage }}
                </div>

                <!-- Error banner -->
                <div v-if="errorMessage" class="error-banner preferences" aria-live="assertive">
                    <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                    {{ errorMessage }}
                </div>
            </div>
        </div>

        <!-- Do not disturb section -->
        <div class="do-not-disturb-section">

            <div class="do-not-disturb-text">
                <p>Do Not Disturb</p>
                <p class="do-not-disturb-desc">Select to not appear on other user's matching screens</p>
            </div>

            <div class="do-not-disturb-button">
                <label class="slider">
                    <input type="checkbox" v-model="doNotDisturb" @change="updatePreferences" tabindex="0"
                        @keydown="handleDisturbKeyDown" aria-label="Do Not Disturb Slider"
                        :aria-checked="doNotDisturb" />
                    <span class="slider-round"></span>
                </label>
            </div>

        </div>

        <!-- Connection Preference Section -->
        <h4 class="preference-section-title">Consider me for interactions...</h4>
        <div class="interaction-preference-section">

            <!-- In person -->
            <label>
                <input type="checkbox" v-model="inPersonOpportunities" @change="updatePreferences"
                    :aria-checked="inPersonOpportunities" />
                <span>In-Person</span>
            </label>

            <!-- Virtual -->
            <label>
                <input type="checkbox" v-model="virtualOpportunities" @change="updatePreferences"
                    :aria-checked="virtualOpportunities" />
                <span>Virtual</span>
            </label>

            <!-- Out of work -->
            <label>
                <input type="checkbox" v-model="outOfWorkOpportunities" @change="updatePreferences"
                    :aria-checked="outOfWorkOpportunities" />
                <span>Out of Work</span>
            </label>

        </div>

        <!-- Office Day Preference Section -->
        <h4 class="preference-section-title">Typical Office Days</h4>
        <div class="office-day-preference-section">

            <!-- Monday -->
            <label>
                <input type="checkbox" v-model="monOffice" @change="updatePreferences" :aria-checked="monOffice" />
                <span>{{ monPrefText }}</span>
            </label>

            <!-- Tuesday -->
            <label>
                <input type="checkbox" v-model="tuesOffice" @change="updatePreferences" :aria-checked="tuesOffice" />
                <span>{{ tuesPrefText }}</span>
            </label>

            <!-- Wednesday -->
            <label>
                <input type="checkbox" v-model="wedsOffice" @change="updatePreferences" :aria-checked="wedsOffice" />
                <span>{{ wedsPrefText }}</span>
            </label>

            <!-- Thursday -->
            <label>
                <input type="checkbox" v-model="thursOffice" @change="updatePreferences" :aria-checked="thursOffice" />
                <span>{{ thursPrefText }}</span>
            </label>

            <!-- Friday -->
            <label>
                <input type="checkbox" v-model="friOffice" @change="updatePreferences" :aria-checked="friOffice" />
                <span>{{ friPrefText }}</span>
            </label>

        </div>

        <!-- Accessibility Preference Section-->
        <h4 class="preference-section-title">Accessibility</h4>
        <div class="accessibility-preference-section">

            <!-- Dark Mode -->
            <div class="preference-dark-mode-section">

                <div class="dark-mode-text">
                    <p>Dark Mode</p>
                </div>

                <div class="dark-mode-button">
                    <label class="slider">
                        <input type="checkbox" v-model="darkMode" @change="updatePreferences" tabindex="0"
                            @keydown="handleDarkKeyDown" aria-label="Dark Mode Slider" :aria-checked="darkMode" />
                        <span class="slider-round"></span>
                    </label>
                </div>

            </div>

            <!-- Big Font -->
            <div class="preference-big-font-section">

                <div class="big-font-text">
                    <p>Big Font</p>
                </div>

                <div class="big-font-button">
                    <label class="slider">
                        <input type="checkbox" v-model="bigFont" @change="updatePreferences" tabindex="0"
                            @keydown="handleFontKeyDown" aria-label="Big Font Slider" :aria-checked="bigFont" />
                        <span class="slider-round"></span>
                    </label>
                </div>

            </div>

        </div>

    </div>
</template>


<script>
import axiosClient from '../../../axiosClient';

export default {
    name: "ProfilePreferences",
    data() {
        return {
            doNotDisturb: false,
            inPersonOpportunities: false,
            virtualOpportunities: false,
            outOfWorkOpportunities: false,
            monOffice: false,
            tuesOffice: false,
            wedsOffice: false,
            thursOffice: false,
            friOffice: false,
            darkMode: false,
            bigFont: false,
            successMessage: "",
            errorMessage: "",
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        // Display just "Mon" on smaller screens
        monPrefText() {
            return this.windowWidth <= 1175 ? "Mon" : "Monday";
        },
        // Display just "Tue" on smaller screens
        tuesPrefText() {
            return this.windowWidth <= 1175 ? "Tues" : "Tuesday";
        },
        // Display just "Wed" on smaller screens
        wedsPrefText() {
            return this.windowWidth <= 1175 ? "Weds" : "Wednesday";
        },
        // Display just "Thu" on smaller screens
        thursPrefText() {
            return this.windowWidth <= 1175 ? "Thurs" : "Thursday";
        },
        // Display just "Fri" on smaller screens
        friPrefText() {
            return this.windowWidth <= 1175 ? "Fri" : "Friday";
        },
    },
    mounted() {
        // Fetch user's preferences
        this.loadPreferences();

        // Listen for window resize to update displayed text
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        // Remove listener on close
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        async loadPreferences() {
            try {
                // Call fetch preferences
                const res = await axiosClient.get('/users/profile/preferences')
                const preferences = res.data;

                // Store result of call
                this.doNotDisturb = preferences.do_not_disturb;
                this.inPersonOpportunities = preferences.in_person;
                this.virtualOpportunities = preferences.virtual;
                this.outOfWorkOpportunities = preferences.out_of_work;
                this.monOffice = preferences.mon_office;
                this.tuesOffice = preferences.tues_office;
                this.wedsOffice = preferences.weds_office;
                this.thursOffice = preferences.thurs_office;
                this.friOffice = preferences.fri_office;
                this.darkMode = preferences.dark_mode;
                this.bigFont = preferences.big_font

            } catch (error) {
                console.error(error)
            }
        },
        async updatePreferences() {
            try {
                // Prepare request data
                const request_data = {
                    do_not_disturb: this.doNotDisturb,
                    in_person: this.inPersonOpportunities,
                    virtual: this.virtualOpportunities,
                    out_of_work: this.outOfWorkOpportunities,
                    mon_office: this.monOffice,
                    tues_office: this.tuesOffice,
                    weds_office: this.wedsOffice,
                    thurs_office: this.thursOffice,
                    fri_office: this.friOffice,
                    dark_mode: this.darkMode,
                    big_font: this.bigFont
                }

                // Save updated preferences
                const res = await axiosClient.patch('/users/profile/preferences', request_data)
                const preferences = res.data;

                // Update variables with saved data for safety
                this.doNotDisturb = preferences.do_not_disturb;
                this.inPersonOpportunities = preferences.in_person;
                this.virtualOpportunities = preferences.virtual;
                this.outOfWorkOpportunities = preferences.out_of_work;
                this.monOffice = preferences.mon_office;
                this.tuesOffice = preferences.tues_office;
                this.wedsOffice = preferences.weds_office;
                this.thursOffice = preferences.thurs_office;
                this.friOffice = preferences.fri_office;
                this.darkMode = preferences.dark_mode;
                this.bigFont = preferences.big_font

                // Set success message
                this.successMessage = "Preferences Updated"

                // Update big font preference
                this.toggleBigFont();
                this.toggleDarkMode();

                // Hide success message after 10s 
                setTimeout(() => {
                    this.successMessage = "";
                }, 10000);

            } catch (error) {
                console.error(error)

                // Set error message
                this.errorMessage = "Error updating preferences"

                // Hide error message after 10s 
                setTimeout(() => {
                    this.successMessage = "";
                }, 10000);
            }
        },
        handleResize() {
            // Update the window width
            this.windowWidth = window.innerWidth;
        },
        toggleBigFont() {
            // Set big font preference
            localStorage.setItem("bigFont", this.bigFont ? "true" : "false");
            document.body.classList.toggle("big-font", this.bigFont);
        },
        toggleDarkMode() {
            // Set big font preference
            localStorage.setItem("darkMode", this.darkMode ? "true" : "false");
            document.body.classList.toggle("dark-mode", this.darkMode);

            // Trigger event to update PwC logo to dark mode
            window.dispatchEvent(
                new StorageEvent("storage", {
                    key: "darkMode",
                    newValue: this.darkMode.toString(),
                })
            );
        },
        handleDisturbKeyDown(event) {
            // Toggle do not disturb using "Enter" when focused
            if (event.key === 'Enter' || event.key === ' ') {
                this.doNotDisturb = !this.doNotDisturb
                this.updatePreferences();
                event.preventDefault();
            }
        },
        handleDarkKeyDown(event) {
            // Toggle dark mode using "Enter" when focused
            if (event.key === 'Enter' || event.key === ' ') {
                this.darkMode = !this.darkMode
                this.updatePreferences();
                event.preventDefault();
            }
        },
        handleFontKeyDown(event) {
            // Toggle font size using "Enter" when focused
            if (event.key === 'Enter' || event.key === ' ') {
                this.bigFont = !this.bigFont
                this.updatePreferences();
                event.preventDefault();
            }
        },
    }
}
</script>