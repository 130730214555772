<template>
    <!-- Owned Community Connection Card -->
    <div class="connection-container manage">

        <!-- Leave Community -->
        <div class="connection-top leave">
            <LeaveCommunityButton :communityName="ownedCommunity.community_name" @leave-community="handleLeave" />
        </div>

        <!-- Icons at the top of the card -->
        <div class="manage-connections-icons">

            <!-- Conditional Edit / Save Icon-->
            <div class="edit-community" v-if="!membersToggle">
                <!-- Edit icon -->
                <i v-if="!isEditing" class="bi bi-pencil" @click="toggleEditMode" tabindex="0"
                    @keydown="handleEditKeyDown" role="button" aria-label="Open Edit Community"
                    title="Edit Community"></i>

                <!-- Save button -->
                <i v-else-if="isEditing" class="bi bi-check-lg" @click="saveCommunityUpdate" tabindex="0"
                    @keydown="handleSaveKeyDown" role="button" aria-label="Update Community"
                    title="Update Community"></i>
            </div>

            <!-- Error banner / "Edit Community" title -->
            <div class="manage-community-banners">

                <!-- Conditional error banner-->
                <div v-if="errorMessage" class="error-banner attribute" aria-live="assertive">
                    <p><i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i> {{ errorMessage }}</p>
                </div>

                <!-- Else display "Edit Community" if not error in edit mode-->
                <div class="edit-community-title" v-else-if="!errorMessage & isEditing">
                    <p><span class="manage-community-field">Edit Community</span></p>
                </div>
            </div>

            <!-- Community members icon -->
            <div class="manage-community-member-icon" v-if="!isEditing">

                <i class="bi bi-person" v-if="!membersToggle" @click="toggleMembers" tabindex="0"
                    @keydown="handleMembersKeyDown" role="button" aria-label="View Community Members"
                    title="View Community Members"></i>

                <!-- Colour in icon when toggled -->
                <i class="bi bi-person-fill" v-else-if="membersToggle" @click="toggleMembers" tabindex="0"
                    @keydown="handleMembersKeyDown" role="button" aria-label="Hide Community Members"
                    title="Hide Community Members"></i>
            </div>

            <!-- When in edit mode, replace members icon for an "X" -->
            <div class="edit-community-close-icon" v-if="isEditing">
                <i class="bi bi-x" @click="toggleEditMode" tabindex="0" @keydown="handleCloseKeyDown" role="button"
                    aria-label="Close Edit Mode" title="Close Edit Mode"></i>
            </div>
        </div>

        <!-- Community Name -->
        <div v-if="!isEditing" class="connection-name">
            <p>{{ ownedCommunity.community_name }}</p>
        </div>

        <!-- When toggles not pressed, display community info -->
        <div v-if="!membersToggle && !isEditing" class="connection-all-info">

            <!-- Community Description -->
            <div class="manage-community-description">
                <p><span class="manage-community-field">About: </span>{{ ownedCommunity.description }}</p>
            </div>

            <!-- Community Purpose -->
            <div class="manage-community-purpose">
                <p><span class="manage-community-field">Purpose: </span>{{ formattedCommunityPurpose }}</p>
            </div>

            <!-- Community Location -->
            <div class="manage-community-location">
                <p><span class="manage-community-field">Where: </span>{{ formattedCommunityLocation }}</p>
            </div>

        </div>

        <!-- Community Meet Schedule -->
        <div v-if="!membersToggle && !isEditing" class="connection-bottom">
            <p><span class="manage-community-field">Schedule: </span>{{ ownedCommunity.meet_schedule }}</p>
        </div>

        <!-- Edit Mode -->
        <div v-if="isEditing && !membersToggle" class="edit-community-info">

            <!-- Edit Community Name -->
            <div class="edit-community-name">
                <p><span class="manage-community-field">Name: </span><i class="bi bi-exclamation-circle-fill"
                        v-if="nameErrorIcon" role="img" aria-live="assertive" aria-label="Error: Community Name"></i>
                </p>
                <input v-model="communityName" value="communityName" placeholder="Community name"
                    aria-label="Update Community Name" />
            </div>

            <!-- Edit Community Desc -->
            <div class="edit-community-description">
                <p><span class="manage-community-field">About: </span><i class="bi bi-exclamation-circle-fill"
                        v-if="descriptionErrorIcon" role="img" aria-live="assertive" aria-label="Error: About"></i></p>
                <textarea v-model="communityDescription" placeholder="Enter a short description about the community"
                    maxlength="120" aria-label="Update Community Description"></textarea>
            </div>

            <!-- Edit Commmunity Purpose -->
            <div class="edit-community-purpose">
                <p><span class="manage-community-field">Purpose: </span><i class="bi bi-exclamation-circle-fill"
                        v-if="purposeErrorIcon" role="img" aria-live="assertive" aria-label="Error: Purpose"></i></p>
                <select v-model="communityPurpose" aria-label="Update Community Purpose">
                    <option v-for="purpose in purposes" :key="purpose">{{ purpose }}</option>
                </select>
            </div>

            <!-- Edit Community Meet Location-->
            <div class="edit-community-location">
                <p><span class="manage-community-field">Location: </span><i class="bi bi-exclamation-circle-fill"
                        v-if="locationErrorIcon" role="img" aria-live="assertive" aria-label="Error: Meet Location"></i>
                </p>
                <select v-model="communityLocation" aria-label="Update Community Location">
                    <option v-for="location in locations" :key="location">
                        {{ location }}
                    </option>
                </select>
            </div>

            <!-- Edit Community Meet Schedule -->
            <div class="edit-community-schedule">
                <p><span class="manage-community-field">Schedule: </span><i class="bi bi-exclamation-circle-fill"
                        v-if="scheduleErrorIcon" role="img" aria-live="assertive" aria-label="Error: Meet Schedule"></i>
                </p>
                <input v-model="communitySchedule" value="communitySchedule" placeholder="Typical Meet Schedule"
                    aria-label="Update Community Schedule" />
            </div>
        </div>

        <!-- When members togggle pressed, display members -->
        <div v-else-if="membersToggle && !isEditing" class="connection-members" tabindex="0">

            <!-- Common office days -->
            <div class="office-section">
                <span class="bold-text">Common Office Days:</span>
                <div class="office-days" v-if="communityMembers.office">
                    <span :class="{ 'active-day': communityMembers.office.mon_office }" aria-label="Monday">M</span>
                    <span :class="{ 'active-day': communityMembers.office.tues_office }" aria-label="Tuesday">T</span>
                    <span :class="{ 'active-day': communityMembers.office.weds_office }" aria-label="Wednesday">W</span>
                    <span :class="{ 'active-day': communityMembers.office.thurs_office }" aria-label="Thursday">T</span>
                    <span :class="{ 'active-day': communityMembers.office.fri_office }" aria-label="Friday">F</span>
                </div>

            </div>

            <ul>
                <!-- Display owner first and highlight -->
                <li class="connection-owner" aria-label="Community Owner"> {{ communityMembers.owner }}</li>

                <!-- Iterate through members -->
                <li v-for="(member, index) in communityMembers.members" :key="index">
                    {{ member }}
                </li>
            </ul>
        </div>



        <!-- Say Thanks / Schedule Meet buttons -->
        <ConnectionButtons :connection="ownedCommunity" />
    </div>

</template>

<script>
import axiosClient from "../../../axiosClient";
import ConnectionButtons from "./ConnectionButtons.vue";
import LeaveCommunityButton from "./LeaveCommunityButton.vue";

export default {
    name: "OwnedCommunityConnection",
    components: {
        ConnectionButtons,
        LeaveCommunityButton,
    },
    props: {
        ownedCommunity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            // Members
            membersToggle: false,
            communityMembers: [],

            // Edit Toggle 
            isEditing: false,

            // Data variables 
            oldCommunityName: this.ownedCommunity.community_name,
            communityName: this.ownedCommunity.community_name,
            communityDescription: this.ownedCommunity.description,
            communityPurpose: this.ownedCommunity.purpose,
            communityLocation: this.ownedCommunity.meet_location,
            communitySchedule: this.ownedCommunity.meet_schedule,

            // Error handling
            errorMessage: "",
            nameErrorIcon: false,
            descriptionErrorIcon: false,
            purposeErrorIcon: false,
            locationErrorIcon: false,
            scheduleErrorIcon: false,

            // Typelist values
            purposes: ["Learn about business area", "Seeking career advice", "Assistance on work", "Build new friendships"],
            locations: ["In Person", "Virtual", "Out of Work"],
        };
    },
    computed: {
        formattedCommunityPurpose() {
            return this.ownedCommunity?.purpose ? this.ownedCommunity.purpose.replace(/_/g, " ") : "Not Found";
        },
        formattedCommunityLocation() {
            return this.ownedCommunity?.meet_location ? this.ownedCommunity.meet_location.replace(/_/g, " ") : "Not Found";
        },
    },
    methods: {
        toggleEditMode() {
            // Toggle Edit mode
            this.isEditing = !this.isEditing;

            // Save computed typelist values into variables
            this.communityPurpose = this.formattedCommunityPurpose;
            this.communityLocation = this.formattedCommunityLocation;

            if (!this.isEditing) {
                // Clear error message when edit mode toggled off
                this.errorMessage = "";
            }
        },
        async toggleMembers() {
            // Toggle community members
            this.membersToggle = !this.membersToggle;

            // When toggled to true
            if (this.membersToggle) {
                try {

                    // Fetch community members
                    const response = await axiosClient.get(`/connect/community_members/${this.ownedCommunity.community_name}`,);
                    this.communityMembers = response.data;

                } catch (error) {

                    console.error("Failed to fetch community members", error);

                    // Reset members on error
                    this.communityMembers = [];

                }
            }
        },
        async saveCommunityUpdate() {
            // Reset error messages
            this.errorMessage = "";
            this.nameErrorIcon = false;
            this.descriptionErrorIcon = false;
            this.purposeErrorIcon = false;
            this.locationErrorIcon = false;
            this.scheduleErrorIcon = false;

            // FE Validator to check for empty fields
            if (!this.communityName.trim()) {
                this.errorMessage = "Name required.";
                this.nameErrorIcon = true;
                return;
            }
            if (!this.communityDescription.trim()) {
                this.errorMessage = "Description required.";
                this.descriptionErrorIcon = true;
                return;
            }
            if (!this.communityPurpose) {
                this.errorMessage = "Purpose required.";
                this.purposeErrorIcon = true;
                return;
            }
            if (!this.communityLocation) {
                this.errorMessage = "Location required.";
                this.locationErrorIcon = true;
                return;
            }
            if (!this.communitySchedule.trim()) {
                this.errorMessage = "Schedule required.";
                this.scheduleErrorIcon = true;
                return;
            }

            try {

                // Request to save the edited field data
                await axiosClient.patch("/communities/owned", {
                    old_community_name: this.oldCommunityName,
                    community_name: this.communityName,
                    community_description: this.communityDescription,
                    community_purpose: this.communityPurpose,
                    community_meet_location: this.communityLocation,
                    community_meet_schedule: this.communitySchedule
                });

                // Turn off edit mode
                this.isEditing = false;

                // Update "Old community name"
                this.oldCommunityName = this.communityName;

                // Trigger a refresh of the owned communities
                this.$emit("community-updated");

            } catch (error) {
                // Handle BE errors
                if (error.response && error.response.data && error.response.data.message) {
                    // Check if the error is a backend validation error
                    const errorResponse = error.response.data;

                    // Display specific form error if validation fails
                    if (errorResponse.message.includes("community_name")) {

                        // Name error
                        this.errorMessage = "Limit: Max 30 Characters"

                        // Set error icon
                        this.nameErrorIcon = true

                    } else if (errorResponse.message.includes("Community Name already in use")) {
                        // Name error
                        this.errorMessage = "Community Name in use"

                        // Set error icon
                        this.nameErrorIcon = true

                    } else if (errorResponse.message.includes("description")) {

                        // Description error
                        this.errorMessage = "Limit: Max 100 Characters"

                        // Set error icon
                        this.descriptionErrorIcon = true

                    } else if (errorResponse.message.includes("purpose")) {

                        // Purpose error
                        this.errorMessage = errorResponse.message

                        // Set purpose icon
                        this.purposeErrorIcon = true

                    } else if (errorResponse.message.includes("location")) {

                        // Location error
                        this.errorMessage = errorResponse.message

                        // Set purpose icon
                        this.locationErrorIcon = true

                    } else if (errorResponse.message.includes("schedule")) {

                        // Schedule error
                        this.errorMessage = "Limit: Max 30 Characters"

                        // Set schedule icon
                        this.scheduleErrorIcon = true

                    }
                } else {
                    // General error
                    this.errorMessage = "Update Error.";

                }
            }
        },
        handleLeave() {
            // Request a refresh to the community list if leave is triggered
            this.$emit("leave-owned-community");
        },
        handleMembersKeyDown(event) {
            // Handle pressing enter on the members icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleMembers();
                event.preventDefault();
            }
        },
        handleEditKeyDown(event) {
            // Handle pressing enter on the edit icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleEditMode();
                event.preventDefault();
            }
        },
        handleSaveKeyDown(event) {
            // Handle pressing enter on the save icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.saveCommunityUpdate();
                event.preventDefault();
            }
        },
        handleCloseKeyDown(event) {
            // Handle pressing enter on the close icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleEditMode();
                event.preventDefault();
            }
        },
    },
};
</script>