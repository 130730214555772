<template>
    <div class="attributes-container last">

        <!-- Section Title -->
        <h3 class="profile-screen-top-section">Certifications</h3>

        <!-- Search Section -->
        <div class="attributes-search-section">

            <!-- Input box -->
            <input class="settings-input" v-model="newAttribute" placeholder="Add Certifications"
                @keyup.enter="addAttribute(newAttribute)" @input="searchBox" aria-label="Add Certifications" />

            <!-- Search results -->
            <div class="attributes-search-results" v-if="searchResult.length > 0">

                <ul class="attributes-search-results-list">

                    <!-- List each result, display no more than 3 -->
                    <li v-for="(result, index) in searchResult.slice(0, 3)" :key="index" @click="addAttribute(result)"
                        class="attributes-search-result-item">
                        {{ result }}
                    </li>

                </ul>

            </div>
        </div>

        <!-- Information banners -->
        <div class="information-banners">

            <!-- Conditional success banner -->
            <div v-if="successMessage" class="success-banner attribute" aria-live="polite">
                <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                {{ successMessage }}
            </div>

            <!-- Conditional error banner-->
            <div v-if="errorMessage" class="error-banner attribute" aria-live="assertive">
                <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                {{ errorMessage }}
            </div>

        </div>

        <!-- Attributes box -->
        <div class="attributes-box">

            <!-- Display attributes as badges-->
            <div class="attribute-badges" v-if="myAttributesList.length > 0">

                <span class="badge" v-for="attribute in myAttributesList" :key="attribute">
                    {{ attribute }}
                    <i class="bi bi-x-circle attributes-remove" @click="removeAttribute(attribute)" tabindex="0"
                        @keydown="(event) => handleKeyDown(event, attribute)" role="button"
                        aria-label="Remove Certification"></i>
                </span>

            </div>

            <!-- When no attributes available -->
            <div class="attributes-no-badges" v-else>
                Add certifications using the search box above
            </div>

        </div>
    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';

export default {
    name: "AttributesCertifications",
    data() {
        return {
            successMessage: "",
            errorMessage: "",
            myAttributesList: [],
            otherAttributesList: [],
            newAttribute: "",
            searchResult: [],
        };
    },
    mounted() {
        // Load user's attributes
        this.fetchMyAttributes();

        // Load attributes for search box
        this.fetchOtherAttributes();

        // Listen for when user clicks off search box
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
        // Remove listener when unmount
        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        async fetchMyAttributes() {
            try {

                // Fetch user's current attributes
                const res = await axiosClient.get('/users/profile/certifications');

                // Store attributes as a list
                this.myAttributesList = res.data.attributes;

            } catch (error) {

                console.error(error);

            }
        },
        async fetchOtherAttributes() {
            try {

                // Fetch attributes not associated to the user
                const res = await axiosClient.get('/users/profile/certifications/other');

                // Store other attributes as a list
                this.otherAttributesList = res.data.attributes;
            } catch (error) {

                console.error(error);

            }
        },
        async removeAttribute(attribute) {

            // Reset error and success messages 
            this.errorMessage = ""
            this.successMessage = ""

            try {

                // Delete the given attribute
                await axiosClient.delete('/users/profile/certifications', {
                    data: { attribute: attribute }
                });

                // Refresh my attributes and other attributes lists
                this.fetchMyAttributes();
                this.fetchOtherAttributes();

                // Set success message
                this.successMessage = "Removed " + attribute;

                // Update prompt banner
                this.$emit("profile-updated")

                // Hide success message after 10s 
                setTimeout(() => {
                    this.successMessage = "";
                }, 10000);

            } catch (error) {

                // Check if a specific backend error is returned 
                if (error.response && error.response.data && error.response.data.message) {

                    // Set error message to backend error 
                    this.errorMessage = error.response.data.message;

                } else {

                    // Generic error message
                    this.errorMessage = "An unexpected error occurred";

                }

                // Hide error message after 10s
                setTimeout(() => {
                    this.errorMessage = "";
                }, 10000);

            }
        },
        async addAttribute(newAttribute) {

            // Reset error and success messages 
            this.errorMessage = ""
            this.successMessage = ""

            // If no value entered, return
            if (!newAttribute) {
                return;
            }

            try {

                // Add the given attribute 
                await axiosClient.post('/users/profile/certifications', { attribute: newAttribute });

                // Refresh my attributes and other attributes lists
                this.fetchMyAttributes();
                this.fetchOtherAttributes();

                // Set success message
                this.successMessage = "Added " + newAttribute;

                // Clear search field and search result
                this.newAttribute = "";
                this.searchResult = [];

                // Update prompt banner
                this.$emit("profile-updated")

                // Hide success message after 10s
                setTimeout(() => {
                    this.successMessage = "";
                }, 10000);

            } catch (error) {

                // Check if a specific backend error is returned
                if (error.response && error.response.data && error.response.data.message) {

                    // Set error message to backend error
                    this.errorMessage = error.response.data.message;

                } else {

                    // Generic error message
                    this.errorMessage = "An unexpected error occurred";

                }

                // Hide error message after 10s
                setTimeout(() => {
                    this.errorMessage = "";
                }, 10000);
            }
        },
        searchBox() {
            // Set new attribute to lower case
            const searchInput = this.newAttribute.toLowerCase();

            // Set search result to items in the other attributes list containing the lower case input
            this.searchResult = this.otherAttributesList.filter((att) =>
                att.toLowerCase().includes(searchInput)
            );

        },
        handleClickOutside(event) {

            // Listen for a click outside of the search section and search results
            const searchSection = this.$el.querySelector(".attributes-search-section");
            const searchResults = this.$el.querySelector(".attributes-search-results");

            // If a click is detected, hide seach results
            if (
                (!searchSection || !searchSection.contains(event.target)) &&
                (!searchResults || !searchResults.contains(event.target))
            ) {
                this.searchResult = [];
            }
        },

        handleKeyDown(event, attribute) {
            // Handle pressing enter on the remove attribute icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.removeAttribute(attribute);
                event.preventDefault();
            }

        },
    }

};
</script>
