<template>
    <button @click="createUser" class="primary-button" aria-label="Create Account Button">Create Account</button>
</template>

<script>
import axiosClient from '../../../axiosClient';

export default {
    name: "CreateAccountButton",
    props: {
        firstName: { type: String, required: true },
        lastName: { type: String, required: true },
        email: { type: String, required: true },
        password: { type: String, required: true },
        confPassword: { type: String, required: true },
    },
    methods: {
        async createUser() {
            // Check all fields populated
            if (!this.firstName || !this.lastName || !this.email || !this.password || !this.confPassword) {
                this.$emit("create-error", "All fields are required");
                return;
            }

            // Check passwords match
            if (this.password !== this.confPassword) {
                this.$emit("create-error", "Passwords do not match");
                return;
            }

            try {
                // Send data to WTForms for validation
                const response = await axiosClient.post("/users/create", {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    password: this.password,
                    confirm_password: this.confPassword,
                });

                // Emit a success message
                this.$emit("create-success", response.data.message);
            } catch (error) {
                if (error.response && error.response.data) {
                    const responseData = error.response.data;

                    // Handle WTForms validation errors
                    if (responseData.errors) {
                        const errorMessages = Object.entries(responseData.errors)
                            .map(([_field, msg]) => msg[0]) // eslint-disable-line no-unused-vars
                            .join(" & ");
                        this.$emit("create-error", "Error: " + errorMessages);

                        // Custom error messages
                    } else if (responseData.message && typeof responseData.message === "string" && !responseData.message.includes(".")) {
                        this.$emit("create-error", responseData.message);

                      // Unexpected error messages
                    } else {
                        this.$emit("create-error", "An unexpected error occurred.");
                    }

                    // Handle axios error
                } else {
                    this.$emit("create-error", "Unable to connect to the server. Please try again later.");
                }
            }
        }
    }
}

</script>
