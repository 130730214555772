<template>
    <div class="leave-community" @click="leaveCommunity" tabindex="0" @keydown="handleLeaveKeyDown" aria-label="Leave Community Button">
        <!-- Leave Community Button-->
        <p><i class="bi bi-x"></i> Leave Community</p>
    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';

export default {
    name: "LeaveCommunityButton",
    props: {
        communityName: {
            type: String,
            required: true
        }
    },
    methods: {
        async leaveCommunity() {
            try {

                // Call leave endpoint for the given community
                await axiosClient.delete('/communities/leave', { 
                    data: { "community_name": this.communityName }
                })

                // Trigger a refresh to the community list
                this.$emit("leave-community")

            } catch (error) {   

                console.error(error)
            
            }
        },
        handleLeaveKeyDown(event) {
            // Handle pressing enter on the leave button
            if (event.key === 'Enter' || event.key === ' ') {
                this.leaveCommunity();
                event.preventDefault();
            }
        },
    }
    
}
</script>