<template>
    <!-- Multi Factor Authentication Container-->
    <div class="start-container ">

        <!-- Container title -->
        <h3>Complete Your Login </h3>

        <!-- Error banner if there is an error message  -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <!-- Success message if reset email sent -->
        <div v-if="successMessage" class="success-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ successMessage }}
        </div>

        <!-- Information message if the resend mfa email is sent successfully -->
        <div v-if="informationMessage" class="information-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ informationMessage }}
        </div>

        <!-- Message if the MFA email is sent successfully -->
        <div class="otp-message" v-if="!errorMessage">
            <p>A One Time Password has been sent to your email. Please enter it below to complete your login.</p>
        </div>

        <!-- OTP field -->
        <h4>Enter you OTP</h4>
        <input v-model="otp" placeholder="Enter your One Time Password" aria-label="Enter your One Time Password"
            @keydown.enter="validateOTP" />

        <!-- Send OTP again link -->
        <div class="otp-resend">
            <p>Didn't receive an email? <span @click="resendOTP" role="button" aria-label="Button to Resend OTP">Resend
                    OTP</span></p>
        </div>

        <!-- Disclaimer if MFA is switched off -->
        <div class="mfa-off" v-if="!mfaEnabled">
            <p><i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i> Multi-factor authentication has been
                disabled for testing
                purposes. Please proceed using the "Bypass MFA" button below.</p>
        </div>

        <div class="verification-buttons">

            <!-- Validate OTP button -->
            <button class="primary-button" @click="validateOTP" :disabled="!mfaEnabled"
                aria-label="Button to Validate the Entered OTP">
                Validate OTP
            </button>

            <!-- Back to Login button -->
            <button v-if="mfaEnabled" class="secondary-button" @click="loginNavigation" role="link"
                aria-label="Link to Login Page">
                Back to Login
            </button>

            <!-- Bypass MFA button if it is switched off -->
            <button v-if="!mfaEnabled" class="quartenary-button" @click="successNavigation" role="link"
                aria-label="Link to Home Page">
                Bypass MFA
            </button>

        </div>

    </div>

</template>

<script>
import axiosClient from '../../axiosClient';

export default {
    name: "mfa-page",
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            informationMessage: "",
            otp: "",

            // Enable / Disable MFA toggle for testing
            mfaEnabled: false,
        };
    },
    mounted() {
        // Send the OTP email on load
        this.sendOTP();
    },
    methods: {
        async sendOTP() {

            // If MFA is enabled 
            if (this.mfaEnabled) {
                try {
                    // Clear messages
                    this.errorMessage = ""
                    this.successMessage = ""
                    this.informationMessage = ""

                    // Call the API to send the OTP email
                    const res = await axiosClient.post('/users/send_mfa')

                    // Show error if the email is not sent
                    if (res.data.emailSent) {
                        return
                    } else {
                        // Show error message
                        this.errorMessage = "There was an error sending the one time password email. Please try again later."
                    }

                } catch (error) {

                    // Generic error message
                    this.errorMessage = "There was an error sending the one time password email. Please try again later.";

                }
            } else {
                return;
            }
        },
        async resendOTP() {

            // If MFA enabled
            if (this.mfaEnabled) {
                try {
                    // Clear messages
                    this.errorMessage = ""
                    this.successMessage = ""
                    this.informationMessage = ""

                    // Call the API to send OTP email
                    const res = await axiosClient.post('/users/send_mfa')

                    // If the email is sent
                    if (res.data.emailSent) {

                        // Clear messages
                        this.errorMessage = "";
                        this.successMessage = "";

                        // Show message detailing that the link has been set
                        this.informationMessage = "One time password link sent. Please check your email";

                    } else {
                        // Clear messages
                        this.successMessage = "";
                        this.informationMessage = "";

                        // Show error message
                        this.errorMessage = "There was an error sending the one time password email. Please try again later.";
                    }

                } catch (error) {

                    // Generic error messasge
                    this.errorMessage = "There was an error sending the one time password email. Please try again later.";

                }
            } else {
                return
            }
        },
        async validateOTP() {

            // If MFA enabled
            if (this.mfaEnabled) {
                try {

                    // Reset error / information messages
                    this.errorMessage = ""
                    this.successMessage = ""
                    this.informationMessage = ""

                    // If there is no OTP, display an error message
                    if (!this.otp) {
                        this.errorMessage = "Please enter your One Time Password";
                        return;
                    }

                    // Check if the OTP is valid
                    const res = await axiosClient.post('/users/confirm_mfa', {
                        "otp": this.otp
                    })

                    // If the OTP is valid, display a success message
                    if (res.data.verified) {

                        this.errorMessage = ""
                        this.informationMessage = ""
                        this.successMessage = "Account verified successfully."

                        // Navigate user to home / profile
                        this.successNavigation();

                    } else {

                        // Else display an error message
                        this.successMessage = ""
                        this.informationMessage = ""
                        this.errorMessage = "Account was not able to be verified"

                    }

                } catch (error) {

                    // Clear messages
                    this.successMessage = "";
                    this.informationMessage = "";

                    if (error.response && error.response.data && error.response.data.message) {
                        // Check if the error is a backend validation error
                        const backendError = error.response.data.message;

                        if (backendError.includes("OTP has expired.")) {
                            // Display specific BE error if the code has expired
                            this.errorMessage = backendError;

                        } else if (backendError.includes("Invalid OTP")) {
                            // Display specific BE error if code or email is not valid
                            this.errorMessage = backendError;

                        } else {
                            // General error
                            this.errorMessage = "Account was not able to be verified"
                        }
                    } else {
                        // General error
                        this.errorMessage = "An unexpected error occurred. Please try again";

                    }


                }
            } else {
                return
            }
        },
        loginNavigation() {

            // Back to login button
            this.$router.push('/')

        },
        async successNavigation() {
            try {
                // Check if the user has populated their account information
                const response = await axiosClient.get('/users/profile/empty_fields');
                const emptyField = response.data.account_field_empty;

                // If the user has not, route them to profile, else to the home screen
                if (emptyField) {
                    this.$router.push('/profile');
                } else {
                    this.$router.push('/home');
                }
            } catch (error) {
                this.errorMessage = "Error navigating you to People link. Please try again later."
            }
        }

    },
};
</script>