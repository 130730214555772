<template>
    <!-- Password Reset Container-->
    <div class="start-container ">

        <!-- Container title -->
        <h3>Reset Password</h3>

        <!-- Error banner if there is an error message  -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <!-- Success message if password reset successfully -->
        <div v-if="successMessage" class="success-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ successMessage }}
        </div>

        <!-- Password change fields -->
        <div class="password-reset-fields">

            <!-- New password field -->
            <p>New Password: <i class="bi bi-exclamation-circle-fill" v-if="newPasswordError" role="img"
                    aria-live="assertive" aria-label="Error: New Password"></i></p>
            <input class="settings-input" type="password" v-model="newPassword" placeholder="New password"
                aria-label="Enter your new password" @keydown.enter="resetPassword()">

            <!-- Confirm password field -->
            <p>Confirm Password: <i class="bi bi-exclamation-circle-fill" v-if="confirmPasswordError" role="img"
                    aria-live="assertive" aria-label="Error: Confirm Password"></i></p>
            <input class="settings-input" type="password" v-model="confirmPassword"
                placeholder="Confirm your new password" aria-label="Confirm your new password"
                @keydown.enter="resetPassword()">

        </div>

        <div class="verification-buttons">

            <!-- Reset password button -->
            <button class="primary-button" @click="resetPassword()" aria-label="Button to Reset Password">
                Reset Password
            </button>

            <!-- Back to Login button -->
            <button class="secondary-button" @click="loginNavigation()" role="link" aria-label="Link to Login Page">
                Back to Login
            </button>
        </div>


    </div>

</template>

<script>
import axiosClient from '../../axiosClient';

export default {
    name: "password-reset-page",
    data() {
        return {
            errorMessage: "",
            successMessage: "",

            newPassword: "",
            confirmPassword: "",

            newPasswordError: false,
            confirmPasswordError: false
        };
    },
    methods: {
        async resetPassword() {
            // Reset error handling variables
            this.errorMessage = "";
            this.successMessage = "";
            this.newPasswordError = false;
            this.confirmPasswordError = false;

            if (!(this.newPassword && this.confirmPassword)) {
                // FE Validation - Check all fields are populated
                this.newPasswordError = !this.newPasswordError ? true : false;
                this.confirmPasswordError = !this.confirmPasswordError ? true : false;

                // Display appropriate error
                this.errorMessage = "Please fill in all of the required fields"
                return;


            } else if (this.newPassword !== this.confirmPassword) {
                // FE Validation - Check new password and confirmation match
                this.newPasswordError = !this.newPasswordError ? true : false;
                this.confirmPasswordError = !this.confirmPasswordError ? true : false;

                // Display appropriate error
                this.errorMessage = "Passwords do not match"
                return;
            }

            try {

                // Get token from window URL
                const urlParams = new URLSearchParams(window.location.search)
                const token = urlParams.get("token")

                // If there is no token, display an error message
                if (!token) {
                    this.errorMessage = "Reset password link is not valid";
                    return;
                }

                // Update password
                const response = await axiosClient.patch('/users/reset_pwd', { "token": token, "new_password": this.newPassword, "confirm_password": this.confirmPassword })

                // If the password has been reset
                if (response.data.reset) {
                    // Reset variables
                    this.newPassword = "";
                    this.confirmPassword = "";

                    // Display success message
                    this.successMessage = "Password successfully updated."
                } else {
                    // Reset variables
                    this.newPassword = "";
                    this.confirmPassword = "";

                    // Display error message
                    this.errorMessage = "Error resetting password"
                }

            } catch (error) {

                if (error.response && error.response.data && error.response.data.message) {
                    // Check if the error is a backend validation error
                    const errorMessage = error.response.data.message;


                    if (errorMessage.includes("Password must be at least")) {
                        // Display specific form error if validation fails
                        this.errorMessage = "Password must be at least 8 characters long, include one symbol, and one number";


                    } else if (errorMessage.includes("Passwords do not match")) {
                        // Display if passwords do not match
                        this.errorMessage = "Passwords do not match";


                    } else if (errorMessage.includes("Password reset link is not valid")) {
                        // Display if the token is not valid
                        this.errorMessage = "Password reset link is not valid. Please request a new one.";
                    } else {
                        // General error
                        this.errorMessage = "An unexpected error occurred. Please try again";
                    }
                } else {
                    // General error
                    this.errorMessage = "An unexpected error occurred. Please try again";

                }


            }
        },

        loginNavigation() {

            // Back to login button
            this.$router.push('/')

        }
    },
};
</script>