<template>
    <div>
        <!-- Leaderboard table -->
        <table class="leaderboard-table">

            <!-- Columns -->
            <thead>

                <tr>

                    <th v-if="!hidePos" class="position-col" scope="col">{{ positionTitleText }}</th>
                    <th scope="name-col">Name</th>
                    <th v-if="!hideGrade" scope="grade-col">Grade</th>
                    <th v-if="!hideOffice" scope="office-col">Office</th>
                    <th v-if="!hideLos" scope="los-col">LoS</th>
                    <th scope="kudos-col">Kudos</th>

                </tr>

            </thead>

            <!-- Leaderboard data  -->
            <tbody>

                <!-- Display each user from the search result -->
                <tr v-for="(user, index) in users" :key="user.email"
                    :class="{ 'current-user': currentUser === user.id }">
                    <td v-if="!hidePos" class="position-col">{{ index + 1 }}</td>
                    <td class="name-col">{{ user.first_name }} {{ user.last_name }}</td>
                    <td v-if="!hideGrade" class="grade-col">{{ formatLeaderboardField(user.grade) }}</td>
                    <td v-if="!hideOffice" class="office-col">{{ formatLeaderboardField(user.base_office) }}</td>
                    <td v-if="!hideLos" class="los-col">{{ formatLeaderboardField(user.los) }}</td>
                    <td class="kudos-col">{{ user.kudos }}</td>
                </tr>

                <!-- Default message if no users returned -->
                <tr v-if="users.length === 0">
                    <td>No users found</td>
                </tr>

            </tbody>

        </table>

    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';

export default {
    name: "LeaderboardTable",
    props: {
        grade: {
            type: String,
            required: true,
        },
        baseOffice: {
            type: String,
            required: true,
        },
        los: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Users list
            users: [],

            // Current user ID
            currentUser: 0,

            // Window width
            windowWidth: window.innerWidth,

            // Column hiding flags
            hideGrade: false,
            hideOffice: false,
            hideLos: false,
            hidePos: false,

        };
    },
    mounted() {
        // Fetch the users to be displayed
        this.fetchUsers(this.grade, this.baseOffice, this.los);

        // Calculate if any columns need to be hidden
        this.calculateHidden();

        // Listen for resize
        window.addEventListener("resize", this.handleResize);

    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);

    },
    computed: {
        positionTitleText() {
            // Dynamically change the position column title for small screens
            return this.windowWidth <= 1175 ? "Pos" : "Position";
        },
    },
    methods: {
        async fetchUsers(grade, baseOffice, los) {
            try {

                // Prepare data
                const data = {
                    "grade": grade,
                    "base_office": baseOffice,
                    "los": los
                }

                // Call leaderboard endpoint
                const res = await axiosClient.get('/users/leaderboard', {
                    params: data
                })

                // Process response
                this.users = res.data.leaderboard
                this.currentUser = res.data.user

            } catch (error) {

                console.error(error);

            }
        },
        formatLeaderboardField(field) {
            // Remove _ from grade / office / los
            return field ? field.replace(/_/g, ' ') : '';
        },
        handleResize() {
            // Listen for resize
            this.windowWidth = window.innerWidth;

            // Calculate which columns should be hidden
            this.calculateHidden();
        },
        calculateHidden() {
            // Number of columns to be hidden
            let numHidden = 0;

            // Reset hidden flags
            this.hidePos = false;
            this.hideGrade = false;
            this.hideOffice = false;
            this.hideLos = false;

            // Calulate how many columns need to be hidden depending on screen size
            if (this.windowWidth >= 1100) {
                return;
            } else if (this.windowWidth < 1100 && this.windowWidth >= 900) {
                numHidden = 1;
            } else if (this.windowWidth < 900 && this.windowWidth >= 769) {
                numHidden = 2;
            } else if (this.windowWidth < 769 && this.windowWidth >= 670) {
                numHidden = 1;
            } else if (this.windowWidth < 670 && this.windowWidth >= 550) {
                numHidden = 2;
            } else if (this.windowWidth < 550 && this.windowWidth >= 390) {
                numHidden = 3;
            } else if (this.windowWidth < 390) {
                numHidden = 3;
                this.hidePos = true;
            }

            // Hidden column order
            // ----------------------
            // DISCLAIMER - THIS CODE WAS CREATED BY CHAT GPT 4o 
            // - Prompt - 
            // "For the fields grade / baseOffice / los, how am I able to order them first by if their values are not equal to
            // "All", then by the priority from hide first to last, grade > baseOffice > los. After, set the values
            // of the this.grade / this.baseOffice / this.los variables to true using the priority, only setting the
            // maximum number of columns to hide as the value of numHidden. I am using Vue JS"
            // ----------------------

            // Order columns first by if they have been filtered (!= to All), then by the priority order
            const fields = [
                { key: "hideGrade", value: this.grade !== "All", priority: 1 },
                { key: "hideOffice", value: this.baseOffice !== "All", priority: 2 },
                { key: "hideLos", value: this.los !== "All", priority: 3 },
            ];

            // Sort by priority 
            fields.sort((a, b) => {
                if (a.value === b.value) {
                    return a.priority - b.priority;
                }
                return a.value ? -1 : 1;
            });

            // Set the this.hideGrade / this.hideOffice / this.hideLos variables to true
            fields.forEach((field, index) => {
                if (index < numHidden) {
                    this[field.key] = true;
                }
            });

            // ----------------------
            // END
            // ----------------------
        },

    }
};
</script>
