<!-- Main template when logged in -->
<template>
    <div class="app">
      <header>
        <MainNavbar />
      </header>    
      <main role="main">
          <router-view />
      </main>
      <footer>
        <PageFooter />
      </footer>
    </div>
</template>

<script>
import MainNavbar from "../common/MainNavbar.vue";
import PageFooter from "../common/PageFooter.vue";

export default {
  components: {
    MainNavbar,
    PageFooter
  },
};
</script>

