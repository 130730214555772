<template>
    <!--Create Account Container-->
    <div class="start-container">
        <h3>Create Account</h3>

        <!-- Error banner if there is an error message  -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <!-- Sucess banner and verification notification -->
        <div v-if="successMessage" class="success-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ successMessage }}<span class="verification-error" v-if="verificationError" @click="handleVerification"
                tabindex="0" @keydown="handleVerificationKeyDown" role="link"
                aria-label="Link to Send Verification Link Page">{{ verificationError }}</span>
        </div>

        <!-- Input Boxes -->
        <div class="name-boxes">
            <div class="create-column">
                <h4>First Name</h4>
                <input v-model="firstName" placeholder="First Name" aria-label="Enter your first name" @keydown.enter="triggerCreate" />
            </div>
            <div class="create-column">
                <h4>Last Name</h4>
                <input v-model="lastName" placeholder="Last Name" aria-label="Enter your last name" @keydown.enter="triggerCreate" />
            </div>
        </div>

        <!-- Other fields and conditional error icons -->
        <div class="error-icons">
            <h4>Email
                <i class="bi bi-exclamation-circle-fill" v-if="emailErrorIcon" role="img" aria-live="assertive"
                    aria-label="Error: Email"></i>
            </h4>
            <input v-model="email" placeholder="Email" aria-label="Enter your email" @keydown.enter="triggerCreate" />
            <h4>Password
                <i class="bi bi-exclamation-circle-fill" v-if="passwordErrorIcon" role="img" aria-live="assertive"
                    aria-label="Error: Password"></i>
            </h4>
            <input v-model="password" type="password" placeholder="Password" aria-label="Enter your password" @keydown.enter="triggerCreate" />
            <h4>Confirm Password
                <i class="bi bi-exclamation-circle-fill" v-if="passwordErrorIcon" role="img" aria-live="assertive"
                    aria-label="Error: Confirm Password"></i>
            </h4>
            <input v-model="confPassword" type="password" placeholder="Confirm Password"
                aria-label="Confirm your password" @keydown.enter="triggerCreate" />
        </div>

        <!-- Buttons -->
        <CreateAccountButton ref="createAccount" :firstName="firstName" :lastName="lastName" :email="email" :password="password"
            :confPassword="confPassword" @create-success="handleCreateSuccess" @create-error="handleCreateError" />
        <button class="secondary-button" @click="navigateToLogin" role="link" aria-label="Link to Login Page">Back to
            Login</button>
    </div>
</template>

<script>

import CreateAccountButton from '@/components/common/CreateAccountButton.vue';
import axiosClient from '../../axiosClient';

export default {
    name: "create-account-page",
    components: {
        CreateAccountButton
    },
    data() {
        // Initialise empty variables
        return {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confPassword: "",
            errorMessage: "",
            successMessage: "",
            passwordErrorIcon: false,
            emailErrorIcon: false,
            verificationError: "",
        };
    },
    methods: {
        // Successful Creation
        async handleCreateSuccess(data) {
            console.log("Account creation successful:", data);

            // Reset error message
            this.errorMessage = "";

            // Send verification email
            const verification = await this.sendVerification()

            // If email sent successfully, set success message
            if (verification) {
                this.successMessage = "Account created! Check your email for a verification link. Make sure to also check your spam folder";
            } else {

                // If email is not sent successfully, display success message with a link to the verification page
                this.verificationError = "here"
                this.successMessage = "Account created successfully. However, there was an issue sending your verification email. Please try resending the verification email by clicking "
            }

            // Reset variables
            this.firstName = ""
            this.lastName = ""
            this.email = ""
            this.password = ""
            this.confPassword = ""
            this.passwordErrorIcon = false
            this.emailErrorIcon = false

        },
        // Unsuccessful Creation
        handleCreateError(error) {
            console.error("Account creation failed:", error);
            // Show error message to the user
            this.errorMessage = error;
            this.successMessage = "";

            // Show password error icon if error contains password
            if (this.errorMessage.toLowerCase().includes("password")) {
                this.passwordErrorIcon = true
            } else {
                this.passwordErrorIcon = false
            }

            // Show email error icon if email contains password
            if (this.errorMessage.toLowerCase().includes("email")) {
                this.emailErrorIcon = true
            } else {
                this.emailErrorIcon = false
            }
        },
        // Navigate to Create Account Page
        navigateToLogin() {
            this.$router.push('/');
        },
        // Send verification email
        async sendVerification() {
            try {
                // If there is no email, return
                if (!this.email) {
                    return
                }

                // Send verification email
                const res = await axiosClient.post('/users/verification', {
                    "email": this.email
                })

                // Return if the verification email has been sent
                if (res.data.verificationSent) {
                    return true
                } else {
                    return false
                }


            } catch (error) {
                console.error("Error sending verification email")
            }
        },
        // Link to verification page
        handleVerification() {
            this.$router.push("/verify")
        },
        handleVerificationKeyDown(event) {
            // Hit verification link using "Enter" when focused
            if (event.key === 'Enter' || event.key === ' ') {
                this.handleVerification()
                event.preventDefault();
            }
        },
        triggerCreate() {
            // Trigger Create Account using enter key
            if (this.$refs.createAccount) {
                this.$refs.createAccount.createUser();
            } else {
                this.errorMessage = "Create Account button is not available.";
            }
        },
    },
};
</script>