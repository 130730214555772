<template>
    <!-- Attributes screen section -->
    <div class="attributes-layout">

        <!-- Row -->
        <div class="attributes-row">

            <!-- Container to fill row -->
            <div class="attribute-large-container">

                <!-- Left / Top Column-->
                <div class="attributes-column">
                    <AttributesSkillsAndSpecialisms @profile-updated="handleProfileUpdate" />
                </div>

                <!-- Right / Bottom Column-->
                <div class="attributes-column">
                    <AttributesCertifications @profile-updated="handleProfileUpdate" />
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import AttributesCertifications from './AttributesCertifications.vue';
import AttributesSkillsAndSpecialisms from './AttributesSkillsAndSpecialisms.vue';

export default {
    name: "ProfileSkillsAndSpecialisms",
    components: {
        AttributesCertifications,
        AttributesSkillsAndSpecialisms
    },
    methods: {
        handleProfileUpdate() {
            this.$emit("profile-updated")
        }
    }
}
</script>