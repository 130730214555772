<template>
    <div class="home-container search-create-container create">
        <div class="create-community" v-if="!similarExisting">
            <div class="search-create-top-section">
                <!-- Create a Community Message -->
                <h3>
                    Create Community
                </h3>

                <!-- Create Community Link -->
                <button v-if="windowWidth > 465" class="tertiary-button" @click="navigateToSearch" role="link"
                    aria-label="Link to Home Page">
                    Back to Search
                </button>
            </div>

            <!-- Conditional Error Banner -->
            <div v-if="errorMessage" class="error-banner" aria-live="assertive">
                <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                {{ errorMessage }}
            </div>

            <!-- Conditional success Banner -->
            <div v-if="successMessage" class="success-banner attribute" aria-live="polite">
                <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                {{ successMessage }}
            </div>

            <div class="search-create-criteria">
                <!-- Large Input Boxes -->
                <div class="long-boxes">

                    <!-- Community Name -->
                    <h4>Community Name <i class="bi bi-exclamation-circle-fill" v-if="nameErrorIcon" role="img"
                            aria-live="assertive" aria-label="Error: Community Name"></i></h4>
                    <input v-model="communityName" placeholder="Community Name" aria-label="Enter Community Name" @keydown.enter="create" />

                    <!-- Community Description -->
                    <h4>About <i class="bi bi-exclamation-circle-fill" v-if="descriptionErrorIcon" role="img"
                            aria-live="assertive" aria-label="Error: About"></i></h4>
                    <input v-model="communityDescription" placeholder="Enter a short description about your community"
                        aria-label="Enter Community Description"  @keydown.enter="create" />

                    <div class="create-community-select">

                        <!-- Community Purpose Drop Down -->
                        <div class="create-community-purpose">
                            <h4>Community Purpose <i class="bi bi-exclamation-circle-fill" v-if="purposeErrorIcon"
                                    role="img" aria-live="assertive" aria-label="Error: Purpose"></i>
                            </h4>
                            <select v-model="communityPurpose" aria-label="Select Community Purpose">
                                <option disabled value="">Select a purpose</option>
                                <option v-for="purpose in purposes" :key="purpose" :value="purpose">
                                    {{ purpose }}
                                </option>
                            </select>
                        </div>

                        <!-- Community Location Drop Down -->
                        <div class="create-community-location">
                            <h4>Typical Meet Location <i class="bi bi-exclamation-circle-fill" v-if="locationErrorIcon"
                                    role="img" aria-live="assertive" aria-label="Error: Location"></i>
                            </h4>
                            <select v-model="communityMeetLocation" aria-label="Select Communtity Meet Location">
                                <option disabled value="">Select a location</option>
                                <option v-for="location in locations" :key="location" :value="location">
                                    {{ location }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- Meet Schedule -->
                    <h4>Meet Schedule <i class="bi bi-exclamation-circle-fill" v-if="scheduleErrorIcon" role="img"
                            aria-live="assertive" aria-label="Error: Meet Location"></i></h4>
                    <input v-model="communityMeetSchedule" placeholder="Frequency and/or days of meets"
                        aria-label="Select Community Meet Schedule" @keydown.enter="create" />


                </div>
            </div>
        </div>

        <!-- Load similar existing communities if toggled -->
        <div v-else-if="similarExisting">
            <SimilarCommunitiesContainer :communityPurpose="communityPurpose" :communityName="communityName"
                @new-connection="handleConnection" />
        </div>

        <!-- Create Button -->
        <div class="search-create-button">
            <button v-if="!similarExisting" class="primary-button" @click="create" aria-label="Create Community">{{
                createButtonText }}</button>
        </div>

        <!-- Different button format for similar existing communities -->
        <div v-if="similarExisting" class="similar-communities-buttons">
            <!-- Back button -->
            <button v-if="similarExisting && windowWidth > 768" class="secondary-button" @click="toggleSimilarExisting"
                aria-label="Back to Create Community">
                <i class="bi bi-arrow-left" aria-hidden="true"></i> Back
            </button>

            <!-- Back button -->
            <button v-if="similarExisting && windowWidth <= 768" class="secondary-button" @click="toggleSimilarExisting"
                aria-label="Back to Create Community">
                <i class="bi bi-arrow-left" aria-hidden="true"></i>
            </button>

            <!-- Create Button -->
            <button class="primary-button" @click="create" aria-label="Create Community">{{ createButtonText }}</button>

            <!-- Unused Placeholder Button -->
            <button class="secondary-button placeholder-button" aria-hidden="true"></button>

        </div>

    </div>
</template>

<script>
import axiosClient from "../../../axiosClient";
import SimilarCommunitiesContainer from "./SimilarCommunitiesContainer.vue";

export default {
    name: "CreateCommunityContainer",
    components: {
        SimilarCommunitiesContainer
    },
    data() {
        return {
            // Field Values
            communityName: "",
            communityDescription: "",
            communityPurpose: "",
            communityMeetSchedule: "",
            communityMeetLocation: "",

            // Drop Down Values
            purposes: [
                "Learn about a business area",
                "Seeking career advice",
                "Assistance on some work",
                "Build new friendships",
            ],
            locations: [
                "In Person",
                "Virtual",
                "Out of Work"
            ],

            // Error / Success
            successMessage: "",
            errorMessage: "",
            nameErrorIcon: false,
            descriptionErrorIcon: false,
            purposeErrorIcon: false,
            scheduleErrorIcon: false,
            locationErrorIcon: false,

            // Window listener
            windowWidth: window.innerWidth,

            // Similar Existing Toggle
            similarExisting: false,
        };
    },
    mounted() {
        // Listen for window resize to update displayed text
        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        // Remove listener on close
        window.removeEventListener("resize", this.handleResize);
    },
    computed: {
        // Display just "Create" on small screens
        createButtonText() {
            return this.windowWidth <= 768 ? "Create" : "Create Community";
        },
    },
    methods: {
        navigateToSearch() {
            // "Back to Search" button
            this.$router.push("/home");
        },
        async create() {
            // Set errors as false when create pressed
            this.nameErrorIcon = false;
            this.descriptionErrorIcon = false;
            this.purposeErrorIcon = false;
            this.scheduleErrorIcon = false;
            this.locationErrorIcon = false;
            this.successMessage = "";
            this.errorMessage = "";

            // FE Validation
            if (this.communityName.trim() === "") {
                this.errorMessage = "Please provide a community name.";
                this.nameErrorIcon = true;
                return;
            }

            if (this.communityDescription.trim() === "") {
                this.errorMessage = "Please provide a description of your community.";
                this.descriptionErrorIcon = true;
                return;
            }

            if (this.communityPurpose === "") {
                this.errorMessage = "Please select a community purpose.";
                this.purposeErrorIcon = true
                return;
            }

            if (this.communityMeetLocation === "") {
                this.errorMessage = "Please select a typical meet location.";
                this.locationErrorIcon = true
                return;
            }

            if (this.communityMeetSchedule === "") {
                this.errorMessage = "Please provide a typical meet schedule.";
                this.scheduleErrorIcon = true
                return;
            }

            // Clear error message if all validations pass
            this.errorMessage = "";

            // Display similar existing communities if not currently
            if (!this.similarExisting) {
                this.similarExisting = true
                return
            }

            // Prepare request
            const requestData = {
                community_name: this.communityName,
                community_description: this.communityDescription,
                community_purpose: this.communityPurpose,
                community_meet_schedule: this.communityMeetSchedule,
                community_meet_location: this.communityMeetLocation,
            };

            try {
                // Create the community
                await axiosClient.post("/communities/create", requestData);

                // Set the success message
                this.successMessage = "Successfully created community: " + this.communityName

                // Trigger community display refresh
                this.$emit('community-created')

                // Empty the fields
                this.communityName = ""
                this.communityDescription = ""
                this.communityPurpose = ""
                this.communityMeetLocation = ""
                this.communityMeetSchedule = ""

                // Switch back to create form
                this.similarExisting = false

            } catch (error) {
                // Switch back to create form if an error
                this.similarExisting = false

                if (error.response && error.response.data && error.response.data.message) {
                    // Check if the error is a backend validation error
                    const errorResponse = error.response.data;


                    // Display specific form error if validation fails
                    if (errorResponse.message.includes("community_name")) {

                        // Name error
                        this.errorMessage = "Community Name cannot exceed 30 characters"

                        // Set error icon
                        this.nameErrorIcon = true

                    } else if (errorResponse.message.includes("Community Name already in use")) {
                        // Name error
                        this.errorMessage = "Community Name already in use"

                        // Set error icon
                        this.nameErrorIcon = true

                    } else if (errorResponse.message.includes("description")) {

                        // Description error
                        this.errorMessage = "Community Description cannot exceed 100 characters"

                        // Set error icon
                        this.descriptionErrorIcon = true

                    } else if (errorResponse.message.includes("purpose")) {

                        // Purpose error
                        this.errorMessage = errorResponse.message

                        // Set purpose icon
                        this.purposeErrorIcon = true

                    } else if (errorResponse.message.includes("location")) {

                        // Location error
                        this.errorMessage = errorResponse.message

                        // Set purpose icon
                        this.locationErrorIcon = true

                    } else if (errorResponse.message.includes("schedule")) {

                        // Schedule error
                        this.errorMessage = "Meet Schedule cannot exceed 30 characters"

                        // Set schedule icon
                        this.scheduleErrorIcon = true

                    }
                } else {
                    // General error
                    this.errorMessage = "An error occurred when creating the community.";

                }

            }
        },
        handleResize() {
            // Update the window width
            this.windowWidth = window.innerWidth;
        },
        toggleSimilarExisting() {
            // Back to create form
            this.similarExisting = false
        },
        handleConnection() {
            // Trigger a refresh if a connection in similar existing 
            this.$emit("new-connection")
        }
    },
};
</script>