<template>
    <!-- User Connection Card -->
    <div class="connection-container">

        <!-- Connection Time -->
        <div class="connection-top">
            <ConnectionTime :time="connection.connection_date" />
        </div>

        <!-- Connection Name / Pronouns / Pronunciation -->
        <div class="connection-name" :title="connection.pronunciation || ''">
            <p>{{ connection.first_name }} {{ connection.last_name }}</p>

            <!-- Pronouns if set -->
            <p class="connection-pronouns"><span :class="{ 'has-pronouns': connection.pronouns }">
                {{ connection.pronouns || 'None' }}
            </span></p>
        </div>

        <!-- Display info -->
        <div class="connection-all-info">

            <!-- Grade / Los / Business Area -->
            <div class="connection-object-info">
                <p>{{ formattedGrade }}</p>
                <p>{{ formattedLos }}</p>
                <p>{{ connection.business_area }}</p>
            </div>

            <!-- Connection Purpose / Connection Location -->
            <div class="connection-details">
                <p><span>Purpose: </span>{{ formattedConnectionPurpose }}</p>
                <p><span>Where: </span>{{ formattedConnectionLocation }}</p>
            </div>

        </div>

        <!-- Email at bottom -->
        <div class="connection-bottom">
            <p>{{ connection.email }}</p>
        </div>

        <!-- Say Thanks / Schedule Meet buttons -->
        <ConnectionButtons :connection="connection" />
    </div>

</template>

<script>
import ConnectionButtons from './ConnectionButtons.vue';
import ConnectionTime from './ConnectionTime.vue';

export default {
    name: "UserConnection",
    props: {
        connection: {
            type: Object,
            required: true,
        },
    },
    components: {
        ConnectionTime,
        ConnectionButtons
    },
    computed: {
        formattedGrade() {
            // Removes underscore from connection.grade, returns "Not Found" if not available
            return this.connection?.grade
                ? this.connection.grade.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedLos() {
            // Removes underscore from connection.los, returns "Not Found" if not available
            return this.connection?.los
                ? this.connection.los.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedConnectionPurpose() {
            // Removes underscore from connection.connection_purpose, returns "Not Found" if not available
            return this.connection?.connection_purpose
                ? this.connection.connection_purpose.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedConnectionLocation() {
            // Removes underscore from connection.connection_location, returns "Not Found" if not available
            return this.connection?.connection_location
                ? this.connection.connection_location.replace(/_/g, ' ')
                : "Not Found";
        }
    }
}
</script>