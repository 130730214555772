<template>
    <div class="navbar">
        <!-- Logo route to login page -->
        <RouterLink to="/" tabindex="0" role="link" aria-label="Link to login screen">
            <img v-if="!darkMode" class="logo" src="/assets/PwC Logo.png" alt="PwC Logo" />
            <img v-if="darkMode" class="logo" src="/assets/Dark PwC Logo.png" alt="PwC Logo"/>
        </RouterLink>
        <!-- Title -->
        <div class="start-nav-title">
            <h1>People Link</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "StartNavbar",
    data() {
        return {
            darkMode: localStorage.getItem("darkMode") === "true",
        }
    }
};
</script>


<style>
@import '../../styles/main.scss';
</style>