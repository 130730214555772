<template>
    <!--Login Container-->
    <div class="start-container">

        <!-- Container title -->
        <h3>Welcome to PwC People Link</h3>

        <!-- Error banner if there is an error message and conditionalverification link -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}<span class="verification-error" v-if="verificationError" @click="handleVerification"
                tabindex="0" @keydown="handleVerificationKeyDown" role="link"
                aria-label="Link to Send Verification Link Page">{{
                    verificationError }}</span>
        </div>

        <!-- Input Boxes -->
        <h4>Email</h4>
        <input v-model="email" placeholder="Enter your email" aria-label="Enter your email"
            @keydown.enter="triggerLogin" />
        <h4>Password</h4>
        <input v-model="password" type="password" placeholder="Enter your password" aria-label="Enter your password"
            @keydown.enter="triggerLogin" />

        <!--Forgot password link-->
        <h5>
            <router-link to="/request-password-reset" class="forgot-password-link"
                aria-label="Link to Request Password Reset Change">Forgot your password?</router-link>
        </h5>

        <div class="verification-buttons">

            <!-- Buttons -->
            <LoginButton ref="loginButton" :email="email" :password="password" @login-success="handleLoginSuccess"
                @login-error="handleLoginError" />
            <button class="secondary-button" @click="navigateToCreateAccount" role="link"
                aria-label="Link to Create Account Page">Create an Account</button>

        </div>
    </div>
</template>


<script>
import LoginButton from '@/components/common/LoginButton.vue';

export default {
    name: "login-page",
    components: {
        LoginButton
    },
    data() {
        // Initialise empty variables
        return {
            email: "",
            password: "",
            errorMessage: "",
            verificationError: ""
        };
    },
    methods: {
        // Successful Login
        async handleLoginSuccess(data) {
            console.log("Login successful:", data);

            // Set error message to empty
            this.errorMessage = "";

            // Navigate the user to the multi-factor authentication page
            this.$router.push('/mfa')

        },
        // Unsuccessful Login
        handleLoginError(error) {
            console.error("Login failed:", error);
            // Show error message to the user
            this.errorMessage = error;
            this.verificationError = "";

            // If error is a verification issue, display link to verification page
            if (error === "Account not verified") {
                this.verificationError = "here"
                this.errorMessage = "Account not verified. Please verify it "
            }
        },
        // Navigate to Create Account Page
        navigateToCreateAccount() {
            this.$router.push('/register');
        },
        // Navigate to verification page
        handleVerification() {
            this.$router.push("/verify")
        },
        handleVerificationKeyDown(event) {
            // Hit verification link using "Enter" when focused
            if (event.key === 'Enter' || event.key === ' ') {
                this.handleVerification()
                event.preventDefault();
            }
        },
        triggerLogin() {
            // Trigger login using enter key
            if (this.email && this.password) {
                this.$refs.loginButton?.login();
            } else {
                this.errorMessage = "Please enter your email and password.";
            }
        },

    },
};
</script>

<style>
@import '../styles/main.scss';
</style>
