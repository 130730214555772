<template>
    <!-- Account View -->
    <div class="profile-container">
        <!-- Display account fields when password change not toggled -->
        <div v-if="!isPasswordChange" class="profile-account-fields">

            <!-- Page name and edit button -->
            <div class="profile-screen-top-section">

                <!-- Page name -->
                <div class="profile-screen-title">
                    <h3>Account</h3>
                </div>

                <!-- Edit button - When pressed display save button -->
                <div class="profile-account-edit">

                    <!-- Edit icon -->
                    <i v-if="!isEditing" class="bi bi-pencil" @click="toggleEditMode" tabindex="0"
                        @keydown="handleKeyDown" role="button" aria-label="Open Edit Mode" title="Edit"></i>

                    <!-- Save button -->
                    <button v-else-if="isEditing" class="primary-button" @click="saveProfileData"
                        aria-label="Save Account">Save</button>

                </div>
            </div>

            <!-- First name and last name -->
            <div class="profile-account-name-section">

                <!-- First name -->
                <div class="profile-account-first-name">
                    <p>First Name: <i class="bi bi-exclamation-circle-fill" v-if="firstNameError" role="img"
                            aria-live="assertive" aria-label="Error: First Name"></i></p>
                    <input class="settings-input" type="text" v-model="firstName" :disabled="!isEditing"
                        aria-label="Enter First Name" />
                </div>

                <!-- Last name -->
                <div class="profile-account-last-name">
                    <p>Last Name: <i class="bi bi-exclamation-circle-fill" v-if="lastNameError" role="img"
                            aria-live="assertive" aria-label="Error: Last Name"></i></p>
                    <input class="settings-input" type="text" v-model="lastName" :disabled="!isEditing"
                        aria-label="Enter Last Name" />
                </div>

                <!-- Pronunciation -->
                <div class="profile-account-pronunciation">
                    <p>Pronunciation: <i class="bi bi-exclamation-circle-fill" v-if="pronunciationError" role="img"
                            aria-live="assertive" aria-label="Error: Pronunciation"></i></p>
                    <input class="settings-input" type="text" v-model="pronunciation" :disabled="!isEditing"
                        aria-label="Enter Name Pronunciation" />
                </div>

            </div>

            <div class="profile-account-pronouns-email-section">
                <!-- Pronouns -->
                <div class="profile-account-pronouns">
                    <p>Pronouns: <i class="bi bi-exclamation-circle-fill" v-if="pronounsError" role="img"
                            aria-live="assertive" aria-label="Error: Pronouns"></i></p>
                    <input class="settings-input" type="text" v-model="pronouns" :disabled="!isEditing"
                        aria-label="Enter Pronouns" />
                </div>

                <!-- Email -->
                <div class="profile-account-email">
                    <p>Email: <i class="bi bi-exclamation-circle-fill" v-if="emailError" aria-hidden="true"><span
                                class="email-error" aria-live="assertive">
                                {{ this.emailErrorMessage }}</span></i></p>
                    <input class="settings-input" type="email" v-model="email" :disabled="!isEditing"
                        aria-label="Enter Email" />
                </div>

            </div>

            <!-- Grade Los and Base Office Typelists-->
            <div class="profile-account-work-section">

                <!-- Grade typelist-->
                <div class="profile-account-grade">
                    <p>Grade: <i class="bi bi-exclamation-circle-fill" v-if="gradeError" role="img"
                            aria-live="assertive" aria-label="Error: Grade"></i></p>
                    <select class="settings-drop-down" v-model="grade" :disabled="!isEditing" aria-label="Enter Grade">
                        <option v-for="grade in grades" :key="grade">{{ grade }}</option>
                    </select>
                </div>

                <!-- Base office typelist-->
                <div class="profile-account-base-office">
                    <p>Base Office: <i class="bi bi-exclamation-circle-fill" v-if="baseOfficeError" role="img"
                            aria-live="assertive" aria-label="Error: Base Office"></i></p>
                    <select class="settings-drop-down" v-model="baseOffice" :disabled="!isEditing"
                        aria-label="Enter Base Office">
                        <option v-for="office in offices" :key="office">{{ office }}</option>
                    </select>
                </div>

                <!-- Los typelist-->
                <div class="profile-account-los">
                    <p>Line of Service: <i class="bi bi-exclamation-circle-fill" v-if="lineOfServiceError" role="img"
                            aria-live="assertive" aria-label="Error: Line of Service"></i></p>
                    <select class="settings-drop-down" v-model="lineOfService" :disabled="!isEditing"
                        aria-label="Enter Line of Service">
                        <option v-for="los in lineOfServices" :key="los">{{ los }}</option>
                    </select>
                </div>
            </div>

            <!-- Business area -->
            <div class="profile-account-bus-area-section">
                <p>Business Area: <i class="bi bi-exclamation-circle-fill" v-if="businessAreaError" role="img"
                        aria-live="assertive" aria-label="Error: Business Area"></i></p>
                <input class="settings-input" type="text" v-model="businessArea" :disabled="!isEditing"
                    aria-label="Enter Business Area" />
            </div>

            <!-- Project and Project end date -->
            <div class="profile-account-project-section">

                <!-- Project -->
                <div class="profile-account-current-project">
                    <p>Current Project: <i class="bi bi-exclamation-circle-fill" v-if="currentProjectError" role="img"
                            aria-live="assertive" aria-label="Error: Current Project"></i></p>
                    <input class="settings-input" type="text" v-model="currentProject" :disabled="!isEditing"
                        aria-label="Enter Current Project" />
                </div>

                <!-- Project end date -->
                <div class="profile-account-project-end">
                    <p>Project End Date: <i class="bi bi-exclamation-circle-fill" v-if="projectEndDateError" role="img"
                            aria-live="assertive" aria-label="Error: Project End"></i></p>
                    <Datepicker class="settings-input" v-model="projectEndDate" :disabled="!isEditing"
                        :format="'YYYY-MM-DD'" @focus="handleFocus" @blur="handleBlur"
                        aria-label="Select Project End Date" />
                </div>
            </div>

            <!-- Change password and Sign out button -->
            <div class="profile-account-bottom-section">

                <!-- Change password button -->
                <button class="secondary-button" @click="togglePasswordChange" :disabled="isEditing"
                    aria-label="Change Password Button">Change
                    Password</button>

                <!-- Sign out button -->
                <button class="signout-button" @click="signout" :disabled="isEditing" aria-label="Sign Out Button">Sign
                    Out</button>
            </div>
        </div>

        <!-- If change password pressed -->
        <div v-else-if="isPasswordChange" class="profile-password-change-container">

            <!-- Update password title -->
            <div class="profile-screen-top-section">
                <h3>Update your password</h3>
            </div>

            <!-- Error Banner -->
            <div v-if="errorMessage" class="error-banner" aria-live="assertive">
                <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                {{ errorMessage }}
            </div>

            <!-- Success Banner -->
            <div v-if="successMessage" class="success-banner" aria-live="assertive">
                <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
                {{ successMessage }}
            </div>

            <!-- Password change fields -->
            <div class="password-change-fields">

                <!-- Current password field -->
                <p>Current Password: <i class="bi bi-exclamation-circle-fill" v-if="currentPasswordError"
                        aria-hidden="true"></i></p>
                <input class="settings-input" type="password" v-model="currentPassword"
                    aria-label="Enter Current Password">

                <!-- New password field -->
                <p>New Password: <i class="bi bi-exclamation-circle-fill" v-if="newPasswordError"
                        aria-hidden="true"></i></p>
                <input class="settings-input" type="password" v-model="newPassword" aria-label="Enter New Password">

                <!-- Confirm password field -->
                <p>Confirm Password: <i class="bi bi-exclamation-circle-fill" v-if="confirmPasswordError"
                        aria-hidden="true"></i></p>
                <input class="settings-input" type="password" v-model="confirmPassword"
                    aria-label="Confirm New Password">

            </div>

            <!-- Update password button -->
            <button class="primary-button" @click="updatePassword" aria-label="Update Password Button">Update
                Password</button>

            <!-- Back to account screen button -->
            <button class="secondary-button" @click="togglePasswordChange" aria-label="Back to Account Fields">Back to
                Account</button>

        </div>
    </div>
</template>


<script>
import axiosClient from "../../../axiosClient";
import Datepicker from "vue3-datepicker";

export default {
    name: "ProfileAccount",
    components: {
        Datepicker,
    },
    data() {
        return {
            // Display toggles
            isEditing: false,
            isPasswordChange: false,

            // Data fields 
            firstName: "",
            lastName: "",
            email: "",
            grade: "",
            baseOffice: "",
            lineOfService: "",
            businessArea: "",
            currentProject: "",
            projectEndDate: new Date(),
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            pronouns: "",
            pronunciation: "",

            // Error icons
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            gradeError: false,
            baseOfficeError: false,
            lineOfServiceError: false,
            businessAreaError: false,
            currentProjectError: false,
            projectEndDateError: false,
            currentPasswordError: false,
            newPasswordError: false,
            confirmPasswordError: false,
            pronounsError: false,
            pronunciationError: false,

            // Email errors
            emailErrorMessage: "",

            // Success and error banners
            errorMessage: "",
            successMessage: "",

            // Typelist values
            grades: [
                "Higher Apprentice", "TDA", "Associate", "Senior Associate", "Manager",
                "Senior Manager", "Director", "Partner", "Specialist"
            ],
            offices: [
                "Aberdeen", "Belfast", "Birmingham", "Bradford", "Bristol",
                "East Midlands", "Cambridge", "Cardiff", "Edinburgh", "Glasgow",
                "Leeds", "London EP", "London ML", "Manchester", "Milton Keynes",
                "Newcastle", "Reading", "Southampton", "Watford"
            ],
            lineOfServices: [
                "Audit", "Business Solutions", "Deals", "Digital Audit", "Consulting",
                "Risk", "Tax", "Tech Central"
            ],
        };
    },
    mounted() {
        // Load the user details on loading the component
        this.fetchProfileData();
    },
    methods: {
        toggleEditMode() {
            // Switch the value of the editing toggle
            this.isEditing = !this.isEditing;
        },
        togglePasswordChange() {
            // Switch the value of the password change toggle
            this.isPasswordChange = !this.isPasswordChange

            // Reset data fields and error messages
            this.errorMessage = false;
            this.successMessage = false;
            this.currentPasswordError = false;
            this.newPasswordError = false;
            this.confirmPasswordError = false;
            this.currentPassword = "";
            this.newPassword = "";
            this.confirmPassword = "";
        },
        async saveProfileData() {
            // Reset error icons on save
            this.firstNameError = false;
            this.lastNameError = false;
            this.emailError = false;
            this.gradeError = false;
            this.baseOfficeError = false;
            this.lineOfServiceError = false;
            this.businessAreaError = false;
            this.currentProjectError = false;
            this.projectEndDateError = false;
            this.pronounsError = false;
            this.pronunciationError = false;
            this.emailErrorMessage = "";

            // Check if the fields have been populated
            if (!this.validateFields()) {
                // Trigger the appropriate error icons for the missing fields
                this.firstNameError = !this.firstName ? true : false;
                this.lastNameError = !this.lastName ? true : false;
                this.emailError = !this.email ? true : false;
                this.gradeError = !this.grade ? true : false;
                this.baseOfficeError = !this.baseOffice ? true : false;
                this.lineOfServiceError = !this.lineOfService ? true : false;
                this.businessAreaError = !this.businessArea ? true : false;
                this.currentProjectError = !this.currentProjectError ? true : false;
                this.projectEndDateError = !this.projectEndDate ? true : false;
                this.emailErrorMessage = "Invalid email address"

                this.accountErrorMessage = "Please populate all account fields."
                return;
            }

            // Fetch field data
            const data = {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                grade: this.grade,
                base_office: this.baseOffice,
                los: this.lineOfService,
                business_area: this.businessArea,
                current_project: this.currentProject,
                project_end: this.projectEndDate,
                pronouns: this.pronouns,
                pronunciation: this.pronunciation
            };

            try {
                // Store updated user data in database
                await axiosClient.patch('/users/profile/account', data);

                // Switch editing toggle
                this.isEditing = false;

                // Reset error icons on successful save
                this.firstNameError = false;
                this.lastNameError = false;
                this.emailError = false;
                this.gradeError = false;
                this.baseOfficeError = false;
                this.lineOfServiceError = false;
                this.businessAreaError = false;
                this.currentProjectError = false;
                this.projectEndDateError = false;
                this.pronounError = false;
                this.pronunciationError = false;
                this.emailErrorMessage = "";

                // Update prompt banner
                this.$emit("profile-updated")

            } catch (error) {

                // Handle error
                if (error.response && error.response.data && error.response.data.message) {
                    try {
                        const errorMessage = error.response.data.message;

                        // Fetch the JSON message in the error
                        const rawMessage = errorMessage.split("Error updating user account: ")[1];

                        if (rawMessage) {
                            // Parse the JSON
                            const parsedMessage = JSON.parse(rawMessage.replace(/'/g, '"'));

                            // Set error flags depending on the parsed error
                            this.firstNameError = Boolean(parsedMessage.first_name);
                            this.lastNameError = Boolean(parsedMessage.last_name);
                            this.emailError = Boolean(parsedMessage.email);
                            this.gradeError = Boolean(parsedMessage.grade);
                            this.baseOfficeError = Boolean(parsedMessage.base_office);
                            this.lineOfServiceError = Boolean(parsedMessage.los);
                            this.businessAreaError = Boolean(parsedMessage.business_area);
                            this.currentProjectError = Boolean(parsedMessage.current_project);
                            this.projectEndDateError = Boolean(parsedMessage.project_end);
                            this.pronounsError = Boolean(parsedMessage.pronouns);
                            this.pronunciationError = Boolean(parsedMessage.pronunciation)

                            // Handle specific email error messages
                            if (this.emailError && Array.isArray(parsedMessage.email)) {

                                // Email in use or Invalid email
                                if (parsedMessage.email.includes("Email already in use")) {

                                    this.emailErrorMessage = "Email already in use";

                                } else if (parsedMessage.email.includes("Invalid email address")) {

                                    this.emailErrorMessage = "Invalid email address";

                                }
                            }
                        }
                    } catch (parseError) {
                        console.error("Error parsing the error message:", parseError);
                    }
                }

                // General error
                console.error("An unexpected error occurred.");
            }
        },
        validateFields() {
            // Check all fields exist
            return (
                this.firstName && this.lastName && this.email && this.grade && this.lineOfService && this.baseOffice && this.businessArea && this.currentProject && this.projectEndDate
            );
        },
        async fetchProfileData() {
            try {
                // Fetch user data
                const users = await axiosClient.get('/users/profile/account')
                const data = users.data

                // Store data in variables and replace underscores where required
                this.firstName = data.first_name;
                this.lastName = data.last_name;
                this.email = data.email;
                this.grade = data.grade.replace(/_/g, ' ');
                this.baseOffice = data.base_office.replace(/_/g, ' ');
                this.lineOfService = data.los.replace(/_/g, ' ');
                this.businessArea = data.business_area;
                this.currentProject = data.current_project;
                this.projectEndDate = new Date(data.project_end);
                this.pronouns = data.pronouns;
                this.pronunciation = data.pronunciation;
            } catch (error) {
                console.error("Error loading user")
            }
        },
        handleFocus() {
            // Allow date picker to remain active
            this.isDatepickerFocused = true;
        },
        handleBlur() {
            // Allow date picker to remain in focus
            this.isDatepickerFocused = false;
        },
        handleKeyDown(event) {
            // Handle pressing enter on the edit icon to toggle edit mode
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleEditMode();
                event.preventDefault();
            }
        },
        async signout() {
            try {
                // Call signout endpoint to unset JWT
                await axiosClient.post('/users/sign_out');

                // Route to login page
                this.$router.push("/");

            } catch (error) {
                console.error(error);
            }

        },
        async updatePassword() {
            // Reset error handling variables
            this.errorMessage = "";
            this.successMessage = "";
            this.currentPasswordError = false;
            this.newPasswordError = false;
            this.confirmPasswordError = false;


            if (!(this.currentPassword && this.newPassword && this.confirmPassword)) {
                // FE Validation - Check all fields are populated
                this.currentPasswordError = !this.currentPassword ? true : false;
                this.newPasswordError = !this.newPasswordError ? true : false;
                this.confirmPasswordError = !this.confirmPasswordError ? true : false;

                // Display appropriate error
                this.errorMessage = "Please fill in all of the required fields"
                return;


            } else if (this, this.newPassword !== this.confirmPassword) {
                // FE Validation - Check new password and confirmation match
                this.newPasswordError = !this.newPasswordError ? true : false;
                this.confirmPasswordError = !this.confirmPasswordError ? true : false;

                // Display appropriate error
                this.errorMessage = "Passwords do not match"
                return;
            }

            try {
                // Update password
                const response = await axiosClient.patch('/users/update_pwd', { "current_password": this.currentPassword, "new_password": this.newPassword, "confirm_password": this.confirmPassword })

                // Reset variables
                this.currentPassword = "";
                this.newPassword = "";
                this.confirmPassword = "";

                // Display success message
                this.successMessage = response.data.message;

            } catch (error) {

                if (error.response && error.response.data && error.response.data.message) {
                    // Check if the error is a backend validation error
                    const errorMessage = error.response.data.message;


                    if (errorMessage.includes("Password must be at least")) {
                        // Display specific form error if validation fails
                        this.errorMessage = "Password must be at least 8 characters long, include one symbol, and one number";


                    } else if (errorMessage.includes("Passwords do not match")) {
                        // Display if passwords do not match
                        this.errorMessage = "Passwords do not match";


                    } else {
                        // Default to display current password error
                        this.errorMessage = "Current password is not correct";
                    }
                } else {
                    // General error
                    this.errorMessage = "An unexpected error occurred. Please try again.";

                }


            }
        },

    },
};
</script>
