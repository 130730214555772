<template>
    <!-- Wait for data to load -->
    <div class="community-recommendation-container" v-if="recommendationData.community">

        <!-- Community Name -->
        <div class="recommended-community-name-section">
            <h3 class="recommended-community-name">{{ recommendationData.community.community_name }}</h3>
            <p> </p>
        </div>

        <!-- Community Description -->
        <div class="recommended-community-description">
            <p><span class="bold-text">About:</span></p>
            <p>{{ recommendationData.community.description }}</p>
        </div>

        <!-- Community Information and Office Days -->
        <div class="recommended-community-info">
            <div class="recommended-community-about-section">
                <p><span class="bold-text">Meeting Location:</span> {{ formattedMeetLocation }}</p>
                <p><span class="bold-text">Meeting Schedule:</span> {{ recommendationData.community.meet_schedule }}</p>
            </div>

            <div class="office-section">
                <p class="recommended-community-office">
                    <span class="bold-text">Common Office Days:</span>
                </p>
                <div class="office-days">
                    <span :class="{ 'active-day': recommendationData.office.mon_office }" aria-label="Monday">M</span>
                    <span :class="{ 'active-day': recommendationData.office.tues_office }" aria-label="Tuesday">T</span>
                    <span :class="{ 'active-day': recommendationData.office.weds_office }" aria-label="Wednesday">W</span>
                    <span :class="{ 'active-day': recommendationData.office.thurs_office }" aria-label="Thursday">T</span>
                    <span :class="{ 'active-day': recommendationData.office.fri_office }" aria-label="Friday">F</span>
                </div>

            </div>
        </div>


        <!-- Skills and Members -->
        <div class="recommended-community-skills-members-section">
            <div class="recommended-community-skills-section">
                <p><span class="bold-text">Common Skills & Interests:</span></p>
                <div class="recommended-community-skill-badges">
                    <span class="badge keyword" v-for="skill in recommendationData.skills_and_interests.searched" :key="skill">
                        {{ skill }}
                    </span>
                    <span class="badge" v-for="skill in recommendationData.skills_and_interests.shared" :key="skill">
                        {{ skill }}
                    </span>
                    <span class="badge other" v-for="skill in recommendationData.skills_and_interests.other"
                        :key="skill">
                        {{ skill }}
                    </span>
                </div>
            </div>

            <!-- Community owner in bold -->
            <div class="recommended-community-members">
                <p><span class="bold-text">Members:</span></p>
                <p><span class="recommended-community-owner" aria-label="Community Owner">{{ recommendationData.owner }}</span></p>
                <p v-for="member in recommendationData.connections" :key="member"><span class="recommended-community-member-connection">{{ member }}</span></p>
                <p v-for="member in recommendationData.members" :key="member">{{ member }}</p>
            </div>
        </div>
    </div>

    <!-- Displayed whilst data loads -->
    <div class="recommendation-loading" v-else>
        <p>Loading recommended community's data...</p>
    </div>
</template>

<script>
export default {
    name: "CommunityRecommendation",
    props: {
        recommendationData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formattedMeetLocation() {
            // Removes underlines from meet_location, returns "Not Found" if not available
            return this.recommendationData?.community?.meet_location
                ? this.recommendationData.community.meet_location.replace(/_/g, ' ')
                : "Not Found";
        },

    }
};
</script>