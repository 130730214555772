<template>
    <!--Manage Connections Container - No Border -->
    <div class="home-container home-container--no-border bottom">


        <!-- Connection grid -->
        <div class="connections-grid">

            <!-- OWNED COMMUNITIES -->
            <div class="connections-container-title">
                <h3>Communities You Manage</h3>
            </div> 

            <!-- For each community in owned communities -->
            <div v-for="(community) in ownedCommunities" :key="community.community_name">

                <!-- Display an Owned Community Connection component containing the data -->
                <OwnedCommunityConnection :ownedCommunity="community" @leave-owned-community="handleOwnedLeave"
                    @community-updated="handleCommunityUpdate" />

            </div>

            <!-- If no ownedCommunities, display prompt -->
            <div v-if="(!ownedCommunities || ownedCommunities.length === 0) && !errorThrown" class="no-connections">

                <p><span class="bold-text">You do not manage any communities.</span></p>
                <p>Get started by creating a community using the create panel.</p>

            </div>

            <!-- If there is an error -->
            <div v-if="errorThrown" class="load-connections-error">

                <p><span class="bold-text">Failed to load your managed communities.</span></p>
                <p>Please refresh the page or try again later.</p>

            </div>

            <!-- JOINED COMMUNITIES -->
            <div class="connections-container-title">
                <h3>Communities You Belong To</h3>
            </div>

            <!-- For each community in joined communities -->
            <div v-for="(community) in joinedCommunities" :key="community.community_name">

                <!-- Display a Joined Community Connection component containing the data -->
                <JoinedCommunityConnection :joinedCommunity="community" @leave-joined-community="handleJoinedLeave" />

            </div>

            <!-- If the user has not joined any communities, display prompt -->
            <div v-if="(!joinedCommunities || joinedCommunities.length === 0) && !errorThrown" class="no-connections">

                <p><span class="bold-text">You have not joined any communities.</span></p>
                <p>Get started by searching for a community using the search panel.</p>

            </div>

            <!-- If there is an error -->
            <div v-if="errorThrown" class="load-connections-error">

                <p><span class="bold-text">Failed to load your joined communities.</span></p>
                <p>Please refresh the page or try again later.</p>

            </div>


        </div>
    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';
import JoinedCommunityConnection from './JoinedCommunityConnection.vue';
import OwnedCommunityConnection from './OwnedCommunityConnection.vue';
import { EventBus } from '../../EventBus';

export default {
    name: "ManageCommunitiesContainer",
    components: {
        OwnedCommunityConnection,
        JoinedCommunityConnection
    },
    data() {
        return {
            ownedCommunities: [],
            joinedCommunities: [],
            errorThrown: false,
        };
    },
    mounted() {

        // Load communities
        this.fetchOwnedCommunities();
        this.fetchJoinedCommunities();

        // Listen for a new connection from quick search bar
        EventBus.on("new-connection", this.handleQuickSearch)
    },
    unmounted() {
        EventBus.off("new-connection", this.handleQuickSearch)

    },
    methods: {
        async fetchOwnedCommunities() {
            try {

                // Fetch owned communities
                const res = await axiosClient.get('/communities/owned')
                this.ownedCommunities = res.data

            } catch (error) {

                // Display error if thrown
                this.errorThrown = true
                console.error(error)

            }
        },
        async fetchJoinedCommunities() {
            try {

                // Fetch owned communities
                const res = await axiosClient.get('/communities/joined')
                this.joinedCommunities = res.data

            } catch (error) {

                // Display error if thrown
                this.errorThrown = true
                console.error(error)

            }
        },
        handleOwnedLeave() {
            // Refresh Owned Communities
            this.fetchOwnedCommunities();
        },
        handleJoinedLeave() {
            // Refresh Joined Communities
            this.fetchJoinedCommunities();
        },
        handleCommunityUpdate() {
            // Refresh Owned Communities
            this.fetchOwnedCommunities()
        },
        handleQuickSearch() {
            // Refresh Joined communities
            this.joinedCommunitie = []
            this.errorThrown = false
            this.fetchJoinedCommunities();
        }
    }
};
</script>