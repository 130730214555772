<template>
    <!--Community Connections Container - no border -->
    <div class="home-container home-container--no-border bottom">
        <!-- Connection grid -->
        <div class="connections-grid">

            <!-- Community Connections Title -->
            <div class="connections-container-title">
                <h3>Community Connections</h3>
            </div>

            <!-- For each connection in community connections -->
            <div v-for="(connection) in communityConnections" :key="connection.connection_date">

                <!-- Dynamically load CommunityConnection -->
                <CommunityConnection :connection="connection" />

            </div>

            <!-- Load More button-->
            <button v-if="!allLoaded && (!communityConnections.length == 0) && !errorThrown"
                class="connections-load-more secondary-button" @click="loadMore" aria-label="Load More Connections">
                Load More
            </button>

            <!-- If there are no connections to display and there has not been an error -->
            <div v-if="(!communityConnections || communityConnections.length === 0) && !errorThrown"
                class="no-connections">

                <p><span class="bold-text">You do not have any community connections.</span></p>
                <p>Get started by searching for a connection using the search panel.</p>

            </div>

            <!-- If there is an error -->
            <div v-if="errorThrown" class="load-connections-error">

                <p><span class="bold-text">Failed to load community connections.</span></p>
                <p>Please refresh the page or try again later.</p>

            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';
import CommunityConnection from './CommunityConnection.vue';
import { EventBus } from '../../EventBus';

export default {
    name: "AllCommunityConnectionsContainer",
    components: {
        CommunityConnection
    },
    data() {
        return {
            communityConnections: [],
            errorThrown: false,
            page: 1,
            perPage: 10,
            allLoaded: false,
        };
    },
    mounted() {
        this.fetchConnections();

        // Listen for a new connection from quick search bar
        EventBus.on("new-connection", this.handleQuickSearch)
    },
    unmounted() {
        EventBus.off("new-connection", this.handleQuickSearch)
    },
    methods: {
        async fetchConnections() {
            if (this.allLoaded) return;

            try {
                // Fetch community connections with pagination
                const response = await axiosClient.get('/connect/community_connections', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                    },
                });

                // If connections were returned, append them
                if (response.data.length > 0) {

                    // Append connection
                    this.communityConnections.push(...response.data);

                    // Increment page
                    this.page += 1;

                    // Check if a full list of results is returned
                    if (response.data.length < 10) {

                        // Hide load button
                        this.allLoaded = true;

                    }
                } else {

                    // Hide load button
                    this.allLoaded = true;

                }


            } catch (error) {

                // Display error block
                this.errorThrown = true;
                console.error(error);

            }
        },
        loadMore() {
            // Fetch another "page"
            this.fetchConnections();
        },
        handleQuickSearch() {
            // Refresh community connections
            this.page = 1
            this.communityConnections = []; 
            this.allLoaded = false; 
            this.errorThrown = false; 
            this.fetchConnections()
        }
    },
};
</script>
