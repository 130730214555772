<template>
    <div class="home-container search-create-container">
        <div class="search-create-top-section">
            <!-- Hello Message -->
            <h3>
                Hello, {{ user.first_name }}<br />
                What are you looking for today?
            </h3>

            <!-- Create Community Link -->
            <button v-if="windowWidth > 465" class="tertiary-button" @click="navigateToManageCommunities">
                Create Community
            </button>
        </div>

        <!-- Error Banner -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <div class="search-create-criteria">
            <!-- Large Input Boxes -->
            <div class="long-boxes">
                <h4>Connection Purpose <i class="bi bi-exclamation-circle-fill" v-if="purposeErrorIcon" role="img"
                    aria-live="assertive" aria-label="Error: Purpose"></i></h4>
                <select v-model="connectionPurpose" aria-label="Select Connection Purpose">
                    <option disabled value="">Select a purpose</option>
                    <option v-for="purpose in purposes" :key="purpose" :value="purpose">
                        {{ purpose }}
                    </option>
                </select>

                <h4>Description of Search Request <i class="bi bi-exclamation-circle-fill"
                        v-if="descriptionErrorIcon" role="img"
                        aria-live="assertive" aria-label="Error: Description"></i></h4>
                <input v-model="searchDescription" placeholder="Enter key words for your search" aria-label="Enter search Description"  @keydown.enter="search"/>
            </div>

            <!-- Checkbox Preferences -->
            <div class="checkboxes">
                <label>
                    <input type="checkbox" v-model="virtualOpportunities" :aria-checked="virtualOpportunities" />
                    <span>Consider virtual opportunities <i class="bi bi-exclamation-circle-fill"
                            v-if="virtualErrorIcon" role="img"
                            aria-live="assertive" aria-label="Error: Virtual Connections"></i></span>
                </label>
                <label>
                    <input type="checkbox" v-model="inPersonOpportunities" :aria-checked="inPersonOpportunities" />
                    <span>Consider in-person opportunities <i class="bi bi-exclamation-circle-fill"
                            v-if="inPersonErrorIcon" role="img"
                            aria-live="assertive" aria-label="Error: In Person Connections"></i></span>
                </label>
                <label>
                    <input type="checkbox" v-model="meetupsOutsideWork" :aria-checked="meetupsOutsideWork" />
                    <span>Consider meetups outside of a work setting <i class="bi bi-exclamation-circle-fill"
                            v-if="outOfWorkErrorIcon" role="img"
                            aria-live="assertive" aria-label="Error: Out of Work Connections"></i></span>
                </label>
                <label>
                    <input type="checkbox" v-model="communityConnections" :aria-checked="communityConnections" />
                    <span>Consider community connections</span>
                </label>
            </div>
        </div>

        <!-- Search Button -->
        <div class="search-create-button">
            <button class="primary-button" @click="search" aria-label="Search">Search</button>
        </div>
    </div>
</template>

<script>
import axiosClient from "../../../axiosClient";

export default {
    name: "SearchContainer",
    data() {
        return {
            // Field values
            user: { first_name: "" },
            connectionPurpose: "",
            purposes: [
                "Learn about a business area",
                "Seeking career advice",
                "Assistance on some work",
                "Build new friendships",
            ],
            searchDescription: "",
            virtualOpportunities: true,
            inPersonOpportunities: true,
            meetupsOutsideWork: true,
            communityConnections: true,

            // Errors
            errorMessage: "",
            purposeErrorIcon: false,
            descriptionErrorIcon: false,
            virtualErrorIcon: false,
            inPersonErrorIcon: false,
            outOfWorkErrorIcon: false,

            // Window Listener
            windowWidth: window.innerWidth,
        };
    },
    mounted() {
        // Listen for window resize to update displayed text
        window.addEventListener("resize", this.handleResize);

        // Fetch user's name
        this.fetchUser();
    },
    beforeUnmount() {
        // Remove listener on close
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        async fetchUser() {
            try {
                // Load user name for title
                const response = await axiosClient.get("/users/load/name");
                this.user = response.data;
            } catch (error) {
                console.error("Error fetching user:", error);
            }
        },
        navigateToManageCommunities() {
            // Go to communities page
            this.$router.push("/communities");
        },
        async search() {
            // Reset error icons
            this.purposeErrorIcon = false;
            this.descriptionErrorIcon = false;
            this.virtualErrorIcon = false;
            this.inPearsonErrorIcon = false;
            this.outOfWorkErrorIcon = false;

            // FE Validation
            if (this.connectionPurpose === "") {
                this.errorMessage = "Please select a connection purpose.";
                this.purposeErrorIcon = true;
                return;
            }

            if (this.searchDescription.trim() === "") {
                this.errorMessage = "Please provide a description of your search.";
                this.descriptionErrorIcon = true;
                return;
            }

            if (!this.virtualOpportunities && !this.inPersonOpportunities && !this.meetupsOutsideWork) {
                this.errorMessage = "Please select at least one preference";
                this.virtualErrorIcon = true;
                this.inPersonErrorIcon = true;
                this.outOfWorkErrorIcon = true;
                return;
            }

            // Clear error icon
            this.errorMessage = "";

            // Request data 
            const requestData = {
                connectionPurpose: this.connectionPurpose,
                searchDescription: this.searchDescription,
                virtualOpportunities: this.virtualOpportunities,
                inPersonOpportunities: this.inPersonOpportunities,
                meetupsOutsideWork: this.meetupsOutsideWork,
                communityConnections: this.communityConnections,
            };

            try {
                // Call search using data
                const recommendationList = await axiosClient.post("/search", requestData);

                // Emit the search has been successful
                this.$emit(
                    "search-request-done",
                    recommendationList.data,
                    this.connectionPurpose,
                    this.searchDescription,
                    this.inPersonOpportunities,
                    this.virtualOpportunities,
                    this.meetupsOutsideWork
                );
            } catch (error) {
                // Display error
                this.errorMessage = "An error occurred during the search.";
            }
        },
        handleResize() {
            // Update the window width
            this.windowWidth = window.innerWidth;
        },
    },
};
</script>
