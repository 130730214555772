import { createRouter, createWebHistory } from 'vue-router';
import StartLayout from '@/components/layouts/StartLayout.vue';
import MainLayout from '@/components/layouts/MainLayout.vue';
import Home from '../views/HomePage.vue';
import Login from '../views/LoginPage.vue'
import Register from '../views/CreateAccountPage.vue'
import AllConnectionsPage from '@/views/AllConnectionsPage.vue';
import ManageCommunitiesPage from '@/views/ManageCommunitiesPage.vue';
import LeaderboardsPage from '@/views/LeaderboardsPage.vue';
import axiosClient from '../../axiosClient';
import ProfilePage from '@/views/ProfilePage.vue';
import VerifyAccount from '@/views/VerifyAccountPage.vue';
import RequestPasswordResetPage from '../views/RequestPasswordResetPage.vue';
import PasswordResetPage from '@/views/PasswordResetPage.vue';
import MfaPage from '@/views/MfaPage.vue';

const routes = [
  // Routes with Start Layout
  {
    path: "/",
    component: StartLayout,
    children: [
      {
        path: "",
        name: "login-page",
        component: Login,
      },
      {
        path: "register",
        name: "create-account-page",
        component: Register,
      },
      {
        path: "request-password-reset",
        name: "request-password-reset-page",
        component: RequestPasswordResetPage,
      },
      {
        path: "password-reset",
        name: "password-reset-page",
        component: PasswordResetPage,
      },
      {
        path: "verify",
        name: "verify-account-page",
        component: VerifyAccount
      },
      {
        path: "mfa",
        name: "mfa-page",
        component: MfaPage,
        meta: { requiresAuth: true },
      },
    ],
  },
  // Main Layout for all other routes
  {
    path: "/home",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "home-page",
        component: Home,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/connections",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "all-connections-page",
        component: AllConnectionsPage,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/communities",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "manage-communities-page",
        component: ManageCommunitiesPage,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/leaderboards",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "leaderboards-page",
        component: LeaderboardsPage,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/profile",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "profile-page",
        component: ProfilePage,
        meta: { requiresAuth: true },
      },
    ],
  },

];

// Save route history
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


// Route guard to be run before each route
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      // Validate the authentication token
      await axiosClient.get("/auth/validate");

      // If successful, proceed to the requested route
      next();
    } catch (error) {
      // If validation fails
      console.error("Token validation failed:", error.response?.data || error.message);

      // Attempt to refresh the token if validation fails
      try {
        console.log("Attempting to refresh the access token");
        await axiosClient.post("/auth/refresh");

        // Retry validation after refresh
        await axiosClient.get("/auth/validate");

        // If valid, proceed to the requested route
        next();
      } catch (refreshError) {
        console.error("Token refresh failed, redirecting to login:", refreshError.response?.data || refreshError.message);

        // Redirect to login page if refresh also fails
        next({ name: "login-page" });
      }
    }
  } else {
    // Allow access to all non-protected routes
    next();
  }
});






export default router;
