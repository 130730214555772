import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

import { createBootstrap } from 'bootstrap-vue-next';

import './styles/main.scss';

import 'bootstrap-icons/font/bootstrap-icons.css';


const app = createApp(App);

app.use(router);
app.use(store);
app.use(createBootstrap()); 

app.mount('#app');

// Register service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered:', registration);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
