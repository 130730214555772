<template>
    <div class="finished-recommendation">
        <h2>End of Recommendations</h2>
        You have reached the end of the recommendation.
        <br>
        <br>
        <div v-if="!hasCommunities">
            There were no community results for your search. If you are seeking a community for your connection purpose,
            create one on the
            <router-link to="/communities" class="all-connections-link"
                aria-label="Link to Communities Page">Communities Page.</router-link>
            <br>
            <br>
        </div>
        Try searching again to view more recommendations, or schedule a meet with
        <router-link to="/connections" class="all-connections-link" aria-label="Link to All Connections Page">your
            connections.</router-link>
    </div>
</template>

<script>
export default {
    name: "FinishedRecommendation",
    props: {
        currentRecommendation: {
            type: Object,
            required: true,
        },
        hasCommunities: {
            type: Boolean,
            required: true
        }
    }
}
</script>

