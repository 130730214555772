<template>
    <div class="main-page-layout profile">

        <div class="main-row profile">

            <!-- Leaderboard filters section -->
            <div class="main-column profile">
                <div class="leaderboard-filters-container">

                    <!-- Kudos points -->
                    <div class="leaderboard-kudos-points">
                        <h3>{{ kudos }} kudos points</h3>
                    </div>


                    <!-- Grade Los and Base Office Filters -->
                    <div class="leaderboard-filters-section">

                        <!-- Grade filter-->
                        <div class="leaderboard-grade-filter">
                            <p>Grade:</p>
                            <select class="settings-drop-down" v-model="grade" aria-label="Leaderboard Grade Filter">
                                <option v-for="grade in grades" :key="grade">{{ grade }}</option>
                            </select>
                        </div>

                        <!-- Base office filter-->
                        <div class="leaderboard-office-filter">
                            <p>Base Office</p>
                            <select class="settings-drop-down" v-model="baseOffice" aria-label="Leaderboard Office Filter">
                                <option v-for="office in offices" :key="office">{{ office }}</option>
                            </select>
                        </div>

                        <!-- Los filter-->
                        <div class="leaderboard-los-filter">
                            <p>Line of Service</p>
                            <select class="settings-drop-down" v-model="lineOfService" aria-label="Leaderboard LoS Filter">
                                <option v-for="los in lineOfServices" :key="los">{{ los }}</option>
                            </select>
                        </div>
                    </div>

                    <!-- Update leaderboard button-->
                    <div class="leaderboard-update-button">
                        <button class="primary-button" @click="fetchUsers()" aria-label="Button to update leaderboard">
                            {{ updateButtonText }}
                        </button>
                    </div>


                </div>
            </div>

            <!-- Leaderboard display -->
            <div class="main-column profile">
                <div class="profile-container">
                    <!-- Leaderboard page name and timer -->
                    <div class="profile-screen-top-section">

                        <!-- Leaderboard name -->
                        <div class="profile-screen-title">
                            <h3>{{ leaderboardTitle }}</h3>
                        </div>

                        <!-- Reset timer -->
                        <div class="profile-account-edit">
                            <h4><i class="bi bi-stopwatch" aria-hidden="true"></i> {{ timerText }}</h4>
                        </div>

                    </div>

                    <!-- Leaderboards table -->
                    <div class="leaderboard-table-section">
                        <LeaderboardTable ref="leaderboardTable" :grade="grade" :baseOffice="baseOffice" tabindex="0"
                            :los="lineOfService" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../axiosClient';
import LeaderboardTable from '../components/common/LeaderboardTable.vue';

export default {
    name: 'leaaderboards-page',
    components: {
        LeaderboardTable,
    },
    data() {
        return {
            // Filter values
            grade: "All",
            baseOffice: "All",
            lineOfService: "All",

            // User's kudosm points
            kudos: 0,

            // Dynamic leaderboard title
            leaderboardTitle: "Leaderboard",

            // Window listener
            windowWidth: window.innerWidth,

            // Typelist values
            grades: [
                "All", "Higher Apprentice", "TDA", "Associate", "Senior Associate", "Manager",
                "Senior Manager", "Director", "Partner", "Specialist"
            ],
            offices: [
                "All", "Aberdeen", "Belfast", "Birmingham", "Bradford", "Bristol",
                "East Midlands", "Cambridge", "Cardiff", "Edinburgh", "Glasgow",
                "Leeds", "London EP", "London ML", "Manchester", "Milton Keynes",
                "Newcastle", "Reading", "Southampton", "Watford"
            ],
            lineOfServices: [
                "All", "Audit", "Business Solutions", "Deals", "Digital Audit", "Consulting",
                "Risk", "Tax", "Tech Central"
            ],
        }
    },
    computed: {
        updateButtonText() {
            // Dynamically change the update leaderboard button's text
            return this.windowWidth <= 1175 ? "Update" : "Update Leaderboard";
        },
        timerText() {
            // Find time until project end date
            const today = new Date();
            const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            const diffInMs = nextMonth - today;

            // Convert ms to days, weeks, and months
            const days = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
            const weeks = Math.floor(days / 7);

            // Return the appropriate message
            if (days < 7) {
                if (days === 1) {
                    return `${days} day`;
                } else {
                    return `${days} days`;
                }
            } else {
                if (weeks === 1) {
                    return `${weeks} week`;
                } else {
                    return `${weeks} weeks`;
                }
            };
        },
    },
    mounted() {
        // Fetch user's kudos points
        this.getKudos();

        // Listen for resize
        window.addEventListener("resize", this.handleResize);

    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);

    },
    methods: {
        fetchUsers() {
            // Call fetch users inside the leaderboard table component 
            this.$refs.leaderboardTable.fetchUsers(this.grade, this.baseOffice, this.lineOfService);

            // Update the leaderboard page title
            this.setLeaderboardTitle();
        },
        setLeaderboardTitle() {
            // Add the filter values to the leaderboard title
            if (this.baseOffice === "All" && this.lineOfService === "All") {
                this.leaderboardTitle = "Leaderboard"
            } else if (this.baseOffice !== "All" && this.lineOfService === "All") {
                this.leaderboardTitle = this.baseOffice + " Leaderboard"
            } else if (this.baseOffice === "All" && this.lineOfService !== "All") {
                this.leaderboardTitle = this.lineOfService + " Leaderboard"
            } else {
                this.leaderboardTitle = this.baseOffice + " " + this.lineOfService + " Leaderboard"
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        },
        async getKudos() {
            // Fetch the user's kudos points
            try {

                // Call kudos endpoint and fetch response
                const res = await axiosClient.get('/users/leaderboard/kudos');
                this.kudos = res.data.kudos;

            } catch (error) {

                console.error(error)

            }
        }
    },
};

</script>
