<template>
    <!--Verify Account Container-->
    <div class="start-container ">

        <!-- Container title -->
        <h3>Account Verification</h3>

        <!-- Error banner if there is an error message  -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <!-- Success message if verified -->
        <div v-if="successMessage" class="success-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ successMessage }}
        </div>

        <!-- Information message if the resend verification email is sent successfully -->
        <div v-if="informationMessage" class="information-banner" aria-live="polite">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ informationMessage }}
        </div>

        <!-- System description if user is verified successfully -->
        <div v-if="successMessage" class="system-desc">
            <p class="system-desc-title">Welcome to PwC PeopleLink</p>
            <p>PwC People Link is an application to allow employees to easily network with others at PwC.</p>
            <p>Upon logging in, you will be prompted to enter information about you. This will be used to improve the
                users and communities that are recommended to you.</p>
            <p>After populating your account, press the PwC icon to start your networking journey. Use the search panel
                to search for a new connection, or create a community.</p>
            <p><span>Please note that the application is available to users outside of PwC. Please refrain from entering
                    sensitive company data.</span></p>
            <p class="system-desc-thanks">Thank you for taking the time to demo Peoplelink. Feedback on the application
                is encouraged. Please forward any comments / questions / suggested improvements to
                mxf105@student.bham.ac.uk.</p>
            <p class="system-desc-thanks">Happy networking!</p>
        </div>

        <!-- Email field if there is an error verifying the user -->
        <div v-if="errorMessage || informationMessage">
            <h4>Email Address</h4>
            <input v-model="newEmail" placeholder="Enter the account's email that you wish to verify" aria-label="Enter your account's email address" @keydown.enter="resendVerification" />
        </div>

        <!-- Nav / Send email buttons -->
        <div class="verification-buttons">

            <!-- Send verification email button -->
            <button v-if="errorMessage || informationMessage" class="primary-button" @click="resendVerification" aria-label="Send verification link">
                Send Verification Email
            </button>

            <!-- Back to Login button (white) -->
            <button v-if="errorMessage || informationMessage" class="secondary-button" @click="loginNavigation" role="link" aria-label="Link to Login page">
                Back to Login
            </button>

            <!-- Back to Login button (orange) -->
            <button v-if="successMessage" class="primary-button" @click="loginNavigation" role="link" aria-label="Link to Login page">
                Back to Login
            </button>


        </div>

    </div>

</template>

<script>
import axiosClient from '../../axiosClient';

export default {
    name: "verify-account-page",
    data() {
        return {
            errorMessage: "",
            successMessage: "",
            informationMessage: "",
            newEmail: "",
        };
    },
    mounted() {
        // Call verify account on page load
        this.verifyAccount()
    },
    methods: {
        async verifyAccount() {
            try {

                // Reset error / information messages
                this.errorMessage = ""
                this.successMessage = ""
                this.informationMessage = ""

                // Get token from window URL
                const urlParams = new URLSearchParams(window.location.search)
                const token = urlParams.get("token")

                // If there is no token, display an error message
                if (!token) {
                    this.errorMessage = "Verification token is missing.";
                    return;
                }

                // Check if the token is valid
                const res = await axiosClient.patch('/users/verification', {
                    "token": token,
                    "expiration": 3600
                })

                // If the token is valid, display a success message
                if (res.data.verified) {

                    this.errorMessage = ""
                    this.successMessage = "Account verified successfully."

                } else {

                    // Else display an error message
                    this.successMessage = ""
                    this.errorMessage = "Account was not able to be verified"

                }

            } catch (error) {

                // Generic error message
                console.error(error)
                this.errorMessage = "Account was not able to be verified"
                this.successMessage = "";

            }
        },
        async resendVerification() {
            try {

                // If the emal field is empty, return
                if (!this.newEmail) {
                    return
                }

                // Call the API to resend the verification email
                const res = await axiosClient.post('/users/verification', {
                    "email": this.newEmail
                })

                if (res.data.verificationSent) {
                    // Clear error and success messagess
                    this.errorMessage = "";
                    this.successMessage = "";

                    // Display an information message that the email has been sent successfully
                    this.informationMessage = "Verification link sent. Please check your email"
                    this.newEmail = ""
                } else {
                    // Clear information and success messagess
                    this.informationMessage = ""
                    this.successMessage = "";                    
                    this.newEmail = ""
                    
                    // Show error message
                    this.errorMessage = "There was an error sending the verification email. Please try again later."
                }


            } catch (error) {

                // Generic error message
                this.errorMessage = "There was an error resending the verification email. Please try again later."

            }
        },
        loginNavigation() {

            // Back to login button
            this.$router.push('/')

        }
    },
};
</script>