<template>
    <div class="similar-existing-communities">
        <div class="search-create-top-section">
            <!-- Similar Existing Communities Message -->
            <h3>
                Similar Existing Communities
            </h3>

        </div>

        <!-- Display similar existing communities-->
        <div class="connections-grid similar">
            <!-- For each connection in similar communities -->
            <div v-for="(connection, index) in similarCommunities" :key="index">

                <!-- Load a similar community component -->
                <SimilarCommunityConnection :connection="connection" @new-connection="handleConnection" />

            </div>
        </div>



    </div>
</template>

<script>
import axiosClient from "../../../axiosClient";
import SimilarCommunityConnection from "./SimilarCommunityConnection.vue";

export default {
    name: "SimilarCommunitiesContainer",
    components: {
        SimilarCommunityConnection
    },
    props: {
        communityName: {
            type: String,
            required: true,
        },
        communityPurpose: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Default loading message to be displayed
            similarCommunities: [{
                "community": {
                    "community_name": "Loading..."
                }
            },
            {
                "community": {
                    "community_name": "Loading..."
                }
            }],

            // Window listener
            windowWidth: window.innerWidth
        };
    },
    mounted() {
        // Fetch similar communities
        this.fetchSimilarCommunities()
    },
    methods: {
        async fetchSimilarCommunities() {
            try {
                // Prepare request data
                const data = {
                    connectionPurpose: this.communityPurpose,
                    searchDescription: this.communityName,
                }

                // Fetch similar communities
                const response = await axiosClient.post('/communities/similar', data);
                const similarCommunitiesIds = response.data;

                // If less than two results returned display an information message 
                if (similarCommunitiesIds.length < 2) {
                    this.similarCommunities = [{
                        "community": {
                            "community_name": "No similar results...",
                        }
                    },
                    {
                        "community": {
                            "community_name": "No similar results..."
                        }
                    }]
                }

                // Iterate through the communities
                for (let i = 0; i < similarCommunitiesIds.length; i++) {
                    try {
                        // Fetch the detailed community profile
                        const requestData = { 'community_id': similarCommunitiesIds[i].community_id }
                        const response = await axiosClient.post("/search/community", requestData)

                        // Save the result to the similar communities list and set connected to false
                        this.similarCommunities[i] = {
                            ...response.data.community,
                            connected: false,
                        };

                    } catch (error) {

                        // Display an error message
                        this.similarCommunities = [{
                            "community": {
                                "community_name": "Error loading..."
                            }
                        },
                        {
                            "community": {
                                "community_name": "Error loading..."
                            }
                        }]
                    }
                }

            } catch (error) {

                // Display an error message
                this.similarCommunities = [{
                    "community": {
                        "community_name": "Error loading..."
                    }
                },
                {
                    "community": {
                        "community_name": "Error loading..."
                    }
                }]

            }
        },
        handleConnection() {
            // Handle if a new connection is made
            this.$emit("new-connection")
        }
    },
};
</script>