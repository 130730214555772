<template>
    <div class="navbar" role="navigation" aria-label="Navigation Bar">
        <!-- PwC Logo -->
        <RouterLink to="/home" :class="{ hidden: isSearchActive }" role="link" aria-label="Link to Home Page">
            <img v-if="!this.darkMode" class="logo" src="/assets/PwC Logo.png" alt="PwC Logo" />
            <img v-if="this.darkMode" class="logo" src="/assets/Dark PwC Logo.png" alt="PwC Logo" />
        </RouterLink>

        <!-- Navigation Links -->
        <div class="navLinks" :class="{ hidden: isSearchActive }">

            <RouterLink to="/connections" class="all-connections-link" aria-label="Link to All Connections Page">
                <h2>{{ connectionsButtonText }}</h2>
            </RouterLink>

            <RouterLink to="/communities" class="manange-communities-link" aria-label="Link to Manage Communities Page">
                <h2>{{ communitiesButtonText }}</h2>
            </RouterLink>

            <RouterLink to="/leaderboards" class="leaderboards-link" aria-label="Link to Leaderboards Page">
                <h2>Leaderboards</h2>
            </RouterLink>

        </div>

        <!-- Right Section -->
        <div class="nav-right-section">
            <!-- Show Search Icon for small screen sizes -->
            <i class="bi bi-search search-icon" v-if="windowWidth <= 1050 && !isSearchActive" tabindex="0"
                @keydown="handleKeyDown" @click="toggleSearchBar" role="button" aria-label="Activate Search Bar"
                title="Search"></i>

            <!-- Show Search Bar when toggled or for large screen sizes-->
            <div class="quick-search" :class="{ active: isSearchActive || windowWidth > 1050 }">

                <input type="text" v-model="searchQuery" placeholder="Search for Users or Communities" aria-label="Search for Users or Communities"
                    @input="handleQuickSearch" />

                <!-- X icon to clear search box -->
                <i class="bi bi-x" @click="clearSearch" tabindex="0" @keydown="handleCloseKeyDown" role="button"
                    aria-label="Clear Search Bar"></i>

            </div>

            <!-- Search Results -->
            <div class="quick-search-results" v-if="searchResults.length > 0 || noResults || errorThrown">

                <!-- Show up to 5 results in the list -->
                <ul class="quick-search-results-list">

                    <li v-if="!errorThrown" v-for="(result, index) in searchResults.slice(0, 5)" :key="index">

                        <!-- If the item is a user-->
                        <div v-if="result.email" class="quick-search-result-item"
                            :class="{ 'no-border': searchResults.length === 1 || index === searchResults.length - 1 }">

                            <!-- Email and Connect button-->
                            <div class="quick-search-result-top">

                                <!-- User email -->
                                <div class="quick-search-result-name">
                                    <p><span class="quick-search-light-bold">{{ result.email }}</span></p>
                                </div>

                                <!-- Connect button -->
                                <div class="quick-search-result-button">

                                    <button class="quartenary-button" :class="{ 'connected-button': result.connected }"
                                        :disabled="result.connected" @click="connect(result)" role="button"
                                        aria-label="Connect with the User">
                                        {{ result.connected ? 'Connected' : 'Connect' }}
                                    </button>

                                </div>

                            </div>

                            <!-- User information -->
                            <div class="quick-search-result-item-body">

                                <!-- General account information -->
                                <div class="quick-search-result-info">

                                    <p><span class="quick-search-bold">{{ result.first_name }} {{ result.last_name
                                            }}</span></p>
                                    <p>{{ formattedGrade(result) }}</p>
                                    <p>{{ formattedLos(result) }}</p>
                                    <p>{{ formattedBaseOffice(result) }}</p>

                                </div>

                                <!-- User's attributes -->
                                <div class="quick-search-attributes-section">

                                    <div class="quick-search-badges">
                                        <span class="badge" v-for="attr in result.attributes.shared" :key="attr">
                                            {{ attr }}
                                        </span>
                                        <span class="badge other" v-for="attr in result.attributes.other" :key="attr">
                                            {{ attr }}
                                        </span>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- If the result is a community -->
                        <div v-if="result.community_name" class="quick-search-result-item"
                            :class="{ 'no-border': searchResults.length === 1 || index === searchResults.length - 1 }">

                            <!-- Community name and connect button -->
                            <div class="quick-search-result-top">

                                <!-- Community name -->
                                <div class="quick-search-result-name">
                                    <p><span class="quick-search-bold">{{ result.community_name }}</span></p>
                                </div>

                                <!-- Connect button -->
                                <div class="quick-search-result-button">

                                    <button class="quartenary-button" :class="{ 'connected-button': result.connected }"
                                        :disabled="result.connected" @click="connect(result)" role="button"
                                        aria-label="Connect with the User">
                                        {{ result.connected ? 'Connected' : 'Connect' }}
                                    </button>

                                </div>

                            </div>

                            <!-- Community information -->
                            <div class="quick-search-result-item-body">

                                <!-- Community details -->
                                <div class="quick-search-result-info">

                                    <p>{{ formattedPurpose(result) }}</p>
                                    <p>{{ formattedLocation(result) }}</p>
                                    <p>{{ result.meet_schedule }}</p>

                                </div>

                                <!-- Community description -->
                                <div class="quick-search-attributes-section">
                                    <p><span class="quick-search-light-bold">About:</span> {{ result.description }}</p>
                                </div>

                            </div>

                        </div>

                    </li>

                    <!-- Display a message if there are no results-->
                    <li v-if="noResults && !errorThrown" class="quick-search-no-results">
                        <div class="quick-search-result-item no-border">
                            <p>{{ noResults }}</p>
                        </div>
                    </li>

                    <!-- Display a message if there is an error -->
                    <li v-if="errorThrown && !noResults" class="quick-search-error">

                        <div class="quick-search-result-item no-border">
                            <p class="quick-search-error">{{ errorThrown }}</p>
                        </div>

                    </li>

                </ul>

            </div>


            <!-- Profile icon to link to profile -->
            <div class="profile-icon" :class="{ hidden: isSearchActive }">
                <RouterLink to="/profile" class="profile-link" aria-label="Link to Profile Page" title="Profile Page">
                    <i class="bi bi-person-fill"></i>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';
import debounce from "lodash.debounce"
import { EventBus } from "../../EventBus";


export default {
    name: "MainNavbar",
    data() {
        return {
            // Search bar
            searchQuery: "",
            isSearchActive: false,
            searchResults: [],
            errorThrown: "",
            noResults: "",

            // Listeners
            windowWidth: window.innerWidth,
            darkMode: localStorage.getItem("darkMode") === "true",
        };
    },
    computed: {
        connectionsButtonText() {
            // Shrink nav link text on small screens
            return this.windowWidth <= 465 ? "Connections" : "All Connections";
        },
        communitiesButtonText() {
            // Shrink nav link text on small screens
            return this.windowWidth <= 465 ? "Communities" : "Manage Communities";
        },
    },
    mounted() {
        // Storage listener
        this.handleStorage = (event) => {
            if (event.key === "darkMode") {
                this.darkMode = localStorage.getItem("darkMode") === "true";
            }
        };

        // Listen for resize
        window.addEventListener("resize", this.handleResize);

        // Update dark mode when event found
        window.addEventListener('storage', this.handleStorage);

        // Listen for click outside search results
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);

        window.removeEventListener("storage", this.handleStorage);

        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        toggleSearchBar() {

            // Turn on search bar   
            this.isSearchActive = true;

        },
        clearSearch() {

            // Clear contents of search bar 
            this.searchQuery = "";
            this.searchResults = [];
            this.errorThrown = "";
            this.noResults = "";
            this.isSearchActive = false

            // Close search bar on small screens
            if (this.windowWidth <= 1050) {
                this.isSearchActive = false;
            }

        },
        handleQuickSearch() {

            // Trigger function when input box used 
            this.quickSearch()

        },
        quickSearch: debounce(async function () {
            // Clear errors
            this.errorThrown = "";
            this.noResults = "";

            // Do not compute if there input is empty
            if (!this.searchQuery.trim()) {
                this.searchResults = []
                return
            }

            try {
                // Make a call to the backend with the search query
                const res = await axiosClient.get("/search/quick_search", {
                    params: { search: this.searchQuery.trim() },
                })

                // Fetch results
                this.searchResults = res.data.map((result) => ({
                    ...result,
                    // Flag to track if "Connect" is pressed
                    connected: false,
                }));

                // If no results returned, display a message
                if (this.searchResults.length === 0) {
                    this.noResults = "No results were found for your search"
                }

            } catch (error) {

                // Display error message if one is thrown
                this.errorThrown = "Error fetching the results of your search"

            }
        }, 300),

        handleResize() {
            this.windowWidth = window.innerWidth

            // Reset search bar when resizing to large screens
            if (this.windowWidth > 1050) {
                this.isSearchActive = false;
            }

        },
        handleClickOutside(event) {

            // Close search box on large screens by clicking outside of it
            if (this.windowWidth > 1050) {

                // Listen for a click outside of the search section and search results
                const searchSection = this.$el.querySelector(".quick-search");
                const searchResults = this.$el.querySelector(".quick-search-results");

                // If click inside search box and search results, ignore
                if (
                    (searchSection && searchSection.contains(event.target)) ||
                    (searchResults && searchResults.contains(event.target))
                ) {
                    return
                }

                // Click outside, close the search results
                this.clearSearch();

            }
        },
        handleKeyDown(event) {
            // Handle pressing enter on the search icon to open the search bar
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleSearchBar();
                event.preventDefault();
            }
        },
        handleCloseKeyDown(event) {
            // Handle closing the search bar by pressing enter when tab focusing on it
            if (event.key === 'Enter' || event.key === ' ') {
                this.clearSearch();
                event.preventDefault();
            }
        },
        async connect(result) {

            // If it is a user
            if (result.email) {

                try {

                    // Fetch user's location preferences
                    const res = await axiosClient.get('/users/profile/preferences')
                    const preferences = res.data;

                    // Store location preferences
                    const inPersonOpportunities = preferences.in_person;
                    const virtualOpportunities = preferences.virtual;
                    const outOfWorkOpportunities = preferences.out_of_work;

                    // Record connection
                    const requestData = {
                        user2_id: result.id,
                        strength: 2, // Positive interaction
                        connected: true, // Connected 
                        connection_purpose: "Learn_about_business_area",   // Default for quick search
                        in_person: inPersonOpportunities,
                        virtual: virtualOpportunities,
                        out_of_work: outOfWorkOpportunities,
                    }

                    // Create interaction
                    await axiosClient.post("/connect/user", requestData);

                    // Trigger refresh in recent connections / all connections / manage communities
                    EventBus.emit("new-connection")

                    // Set result to connected
                    result.connected = true;

                } catch (error) {

                    console.error(error)

                }
            } else {
                // If it is a community 

                try {

                    // Create a community connection
                    const requestData = {
                        community_id: result.id
                    }

                    // Create connection
                    await axiosClient.post("/connect/community", requestData);

                    // Trigger refresh in recent connections / all connections / manage communities
                    EventBus.emit("new-connection")

                    // Set result to connected
                    result.connected = true;

                } catch (error) {

                    console.error(error)

                }
            }
        },
        formattedGrade(result) {
            // Removes underscore from result.grade, returns "Not Found" if not available
            return result.grade
                ? result.grade.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedLos(result) {
            // Removes underscore from result.los, returns "Not Found" if not available
            return result.los
                ? result.los.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedPurpose(result) {
            // Removes underscore from result.purpose, returns "Not Found" if not available
            return result.purpose
                ? result.purpose.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedLocation(result) {
            // Removes underscore from result.location, returns "Not Found" if not available
            return result.meet_location
                ? result.meet_location.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedBaseOffice(result) {
            // Removes underscore from result.location, returns "Not Found" if not available
            return result.base_office
                ? result.base_office.replace(/_/g, ' ')
                : "Not Found";
        }

    },
};
</script>
