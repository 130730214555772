<template>
    <button class="primary-button" @click="login" aria-label="Login Button">Login</button>
</template>

<script>
import axiosClient from '../../../axiosClient';

export default {
    // Login Button with email and password parameters
    name: "LoginButton",
    props: {
        email: {
            type: String,
            required: true,
        },
        password: {
            type: String,
            required: true,
        }
    },
    methods: {
        async login() {
            try {
                // If email or password is empty, return an error
                if (!this.email || !this.password) {
                    const errorMessage = "Email and Password are Required.";
                    this.$emit("login-error", errorMessage);
                    return;
                }

                // Perform the login request using the parameters
                const response = await axiosClient.post("/users/login", {
                    email: this.email,
                    password: this.password,
                });

                // Return login success with response
                this.$emit("login-success", response.data);
            } catch (error) {
                // If an error, return a login error with a detailed error message
                const errorMessage = error.response?.data?.message || "Failed to log in. Please check your credentials.";
                this.$emit("login-error", errorMessage);
            }
        },
    },
};
</script>


<style>
@import '../../styles/main.scss';

</style>


