<template>
    <div class="user-recommendation-container" v-if="recommendationData.account">
        <!-- User Details -->
        <div class="recommended-user-name-section">
            <h3 class="recommended-user-name">{{ recommendationData.account.first_name }} {{
                recommendationData.account.last_name }}</h3>
            <p class="recommended-user-email">{{ recommendationData.account.email }}</p>
        </div>

        <!-- Account Section -->
        <div class="recommended-user-account-section">
            <div class="about-section">
                <p><span class="bold-text">Grade:</span> {{ formattedGrade }}</p>
                <p><span class="bold-text">LoS:</span> {{ formattedLos }}</p>
                <p><span class="bold-text">Business Unit:</span> {{ recommendationData.account.business_area }}</p>
                <p><span class="bold-text">Current Project:</span> {{ recommendationData.account.current_project }}</p>
                <p><span class="bold-text">Duration Left:</span> {{ durationLeft }}</p>
            </div>

            <!-- Office and location preference-->
            <div class="office-section">
                <p class="recommended-user-office-name">
                    <span class="bold-text">Office:</span> {{ formattedBaseOffice }}
                </p>
                <div class="office-days">
                    <span :class="{ 'active-day': recommendationData.account.mon_office }" aria-label="Monday">M</span>
                    <span :class="{ 'active-day': recommendationData.account.tues_office }" aria-label="Tuesday">T</span>
                    <span :class="{ 'active-day': recommendationData.account.weds_office }" aria-label="Wednesday">W</span>
                    <span :class="{ 'active-day': recommendationData.account.thurs_office }" aria-label="Thursday">T</span>
                    <span :class="{ 'active-day': recommendationData.account.fri_office }" aria-label="Friday">F</span>
                </div>

                <!-- Location Meet Preferences-->
                <div class="recommended-user-preference-section">
                    <p><span class="bold-text">Preference: </span>
                        <span>
                            {{ [recommendationData.account.in_person ? "In Person" : null,
                            recommendationData.account.virtual ? "Virtual" : null,
                            recommendationData.account.out_of_work ? "Out of Work" : null]
                                .filter(Boolean)
                                .join(", ") }}
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <!-- Attributes Section -->
        <div class="recommended-user-attributes-section">
            <div class="recommended-user-skills-section">
                <p><span class="bold-text">Skills & Interests:</span></p>
                <div class="recommended-user-skill-badges">
                    <span class="badge keyword" v-for="skill in recommendationData.skills_and_interests.searched" :key="skill">
                        {{ skill }}
                    </span>
                    <span class="badge" v-for="skill in recommendationData.skills_and_interests.shared" :key="skill">
                        {{ skill }}
                    </span>
                    <span class="badge other" v-for="skill in recommendationData.skills_and_interests.other"
                        :key="skill">
                        {{ skill }}
                    </span>
                </div>
            </div>

            <!-- Certifications -->
            <div class="recommended-user-certification-section">
                <p><span class="bold-text">Certifications:</span></p>
                <div class="recommended-user-cert-badges">
                    <span class="badge keyword" v-for="cert in recommendationData.certifications.searched" :key="cert">
                        {{ cert }}
                    </span>
                    <span class="badge" v-for="cert in recommendationData.certifications.shared" :key="cert">
                        {{ cert }}
                    </span>
                    <span class="badge other" v-for="cert in recommendationData.certifications.other" :key="cert">
                        {{ cert }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Displayed whilst data loads -->
    <div class="recommendation-loading" v-else>
        <p>Loading recommended user's data...</p>
    </div>
</template>

<script>
export default {
    name: "UserRecommendation",
    props: {
        recommendationData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        durationLeft() {
            // Get project end date or return "Not Found"
            const projectEnd = this.recommendationData?.account?.project_end
                ? new Date(this.recommendationData.account.project_end)
                : null;
            if (!projectEnd) {
                return "Not Found";
            }

            // Find time until project end date
            const today = new Date();
            const diffInMs = projectEnd - today;

            // If the project has already ended
            if (diffInMs <= 0) {
                return "Project Ended"; 
            }

            // Convert ms to days, weeks, and months
            const days = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
            const weeks = Math.floor(days / 7);
            const months = Math.floor(days / 30); // Approx 30 days in a month

            // Return the appropriate message
            if (days < 7) {
                if (days === 1) {
                    return `${days} day`;
                } else {
                    return `${days} days`;
                }
            } else if (weeks < 4) {
                if (weeks === 1) {
                    return `${weeks} week`;
                } else {
                    return `${weeks} weeks`;
                }
            } else {
                if (months === 1) {
                    return `${months} month`;
                } else {
                    return `${months} months`;
                }
            }
        },
        formattedBaseOffice() {
            // Removes underscore from base office, returns "Not Found" if not available
            return this.recommendationData?.account?.base_office
                ? this.recommendationData.account.base_office.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedGrade() {
            // Removes underscore from grade, returns "Not Found" if not available
            return this.recommendationData?.account?.grade
                ? this.recommendationData.account.grade.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedLos() {
            // Removes underscore from los, returns "Not Found" if not available
            return this.recommendationData?.account?.los
                ? this.recommendationData.account.los.replace(/_/g, ' ')
                : "Not Found";
        },
    },
};
</script>
