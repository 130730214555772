<template>
    <!-- Attributes screen section -->
    <div class="attributes-layout">

        <!-- Row -->
        <div class="attributes-row">

            <!-- Container to fill row -->
            <div class="attribute-large-container">

                <!-- Left / Top Column-->
                <div class="attributes-column">
                    <AttributesWorkInterests @profile-updated="handleProfileUpdate" />
                </div>

                <!-- Right / Bottom Column-->
                <div class="attributes-column">
                    <AttributesPersonalInterests @profile-updated="handleProfileUpdate" />
                </div>

            </div>

        </div>
        
    </div>
</template>

<script>
import AttributesPersonalInterests from './AttributesPersonalInterests.vue';
import AttributesWorkInterests from './AttributesWorkInterests.vue';

export default {
    name: "ProfileInterests",
    components: {
        AttributesWorkInterests,
        AttributesPersonalInterests
    },
    methods: {
        handleProfileUpdate() {
            this.$emit("profile-updated")
        }
    }
}
</script>