<template>
    <!-- Say Thanks and Schedule Meet buttons-->
    <div class="connection-buttons">

        <!-- Say thanks button - Disable after click -->
        <button class="say-thanks" :class="{ 'full-width' : isIOS }" @click="sayThanks()" :disabled="disableThanks" aria-label="Say thanks button" title="Award the connection kudos">
            
            <!-- When disabled change the text to a tick -->
            <span v-if="disableThanks"><i class="bi bi-check"></i></span>

            <span v-else-if="!disableThanks">Say Thanks</span>

        </button>

        <!-- Schedule Meet Button-->
        <button class="schedule-meet" v-if="!bigFont && !isIOS" @click="scheduleMeet()" aria-label="Schedule meet button" title="Schedule Microsoft Teams Meeting">Schedule Meet</button>
        <button class="schedule-meet" v-if="bigFont && !isIOS" @click="scheduleMeet()" aria-label="Schedule meet button" title="Schedule Microsoft Teams Meeting">Meet</button>
    </div>
</template>

<script>
import axiosClient from '../../../axiosClient';

export default {
    name: "ConnectionButtons",
    props: {
        connection: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            disableThanks: false,
            bigFont: localStorage.getItem("bigFont") === "true",

            isIOS: /iPhone|iPad|iPod/i.test(navigator.userAgent),
        }
    },
    methods: {
        async sayThanks() {
            try {
                // When a user
                if (this.connection.email) {
                    // Call thank endpoint with the email
                    await axiosClient.post('/connect/thank', { email: this.connection.email });

                    // Disable button
                    this.disableThanks = true;
                    // When a community
                } else {
                    // Call thank endpoint with the community name
                    await axiosClient.post('/connect/thank', { community_name: this.connection.community_name });

                    // Disable button
                    this.disableThanks = true;
                }
            } catch (error) {
                console.error(error)
            }
        },
        async scheduleMeet() {
            try {
                // Meeting parameters
                let meet_info = {}

                // If the connection is a user
                if (this.connection.email) {

                    // Prepare meeting information
                    meet_info = {
                        connection_email: this.connection.email,
                        purpose: this.connection.connection_purpose,
                        location: this.connection.connection_location
                    }

                // If the connection is a community
                } else if (this.connection.community_name) {
                   
                    // Prepare meeting information
                    meet_info = {
                        connection_community_name: this.connection.community_name,
                        purpose: this.connection.purpose,
                        location: this.connection.meet_location
                    }

                }

                // Generate the MS Teams deep link
                const res = await axiosClient.get('/connect/schedule', {
                    params: meet_info
                })

                // Fetch the MS Teams deep link from the response
                const teamsLink = res.data

                // Redirect user to the MS Teams invite
                window.open(teamsLink, "_blank");

            } catch (error) {

                console.error("An error occurred while creating the Microsoft Teams link:", error);

            }
        },
    }
}
</script>