<template>
    <div class="connection-time">
        <p>{{ formattedConnectionTime }}</p>
    </div>
</template>

<script>
export default {
    name: "ConnectionTime",
    props: {
        time: {
            type: Date,
            required: true
        }
    },
    computed: {
        formattedConnectionTime() {
            // Return "Connected" if no date is found
            if (!this.time) {
                return "Connected"; 
            }

            const now = new Date();
            const targetDate = new Date(this.time);

            // Find time from connection time in ms
            const diffInMs = now - targetDate;

            // Convert to seconds, minutes, hours, days, and weeks
            const seconds = Math.floor(diffInMs / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const weeks = Math.floor(days / 7);
            const months = Math.floor(days / 30); // Approx 30 days in a month
            const years = Math.floor(days / 365); // Approx 365 days in a year

            // Display appropriate messsage
            if (seconds < 60) {
                if (seconds == 1) {
                    return `Connected ${seconds} second ago`;
                } else {
                    return `Connected ${seconds} seconds ago`;
                }
            } else if (minutes < 60) {
                if (minutes == 1) {
                    return `Connected ${minutes} minute ago`;
                } else {
                    return `Connected ${minutes} minutes ago`;
                }
            } else if (hours < 24) {
                if (hours == 1) {
                    return `Connected ${hours} hour ago`;
                } else {
                    return `Connected ${hours} hours ago`;
                }
            } else if (days < 7) {
                if (days == 1) {
                    return `Connected ${days} day ago`;
                } else {
                    return `Connected ${days} days ago`;
                }
            } else if (weeks < 4) {
                if (weeks === 1) {
                    return `Connected ${weeks} week ago`;
                } else {
                    return `Connected ${weeks} weeks ago`;
                }
            } else if (months < 12) {
                if (months === 1) {
                    return `Connected ${months} month ago`;
                } else {
                    return `Connected ${months} months ago`;
                }
            } else {
                if (years === 1) {
                    return `Connected ${years} year ago`;
                } else {
                    return `Connected ${years} years ago`;
                }
            }
        }
    }
}
</script>