<template>
    <div class="main-page-layout">

        <div class="main-row">

            <!-- User Connections on left side of scren / top -->
            <div class="main-column">
                <AllUserConnectionsContainer />
            </div>

            <!-- Manage Communities listed on right side of screen / below -->
            <div class="main-column">
                <AllCommunityConnectionsContainer />
            </div>

        </div>
        
    </div>
</template>

<script>
import AllCommunityConnectionsContainer from '@/components/common/AllCommunityConnectionsContainer.vue';
import AllUserConnectionsContainer from '@/components/common/AllUserConnectionsContainer.vue';


export default {
    name: "all-connections-page",
    components: {
        AllUserConnectionsContainer,
        AllCommunityConnectionsContainer
    },
}
</script>
