<template>
    <div class="main-page-layout profile">

        <!-- Populate profile prompt -->
		<div class="profile-banner" v-if="profileBanner">
			
			<div class="profile-banner-content" aria-live="polite">
				<!-- Text containing route link and dynamic values of profile sections-->
				<p class="profile-banner-text"><i class="bi bi-x-circle attributes-remove" @click="closeBanner" role="button" aria-label="Button to close prompt banner"></i> To improve your experience, please
					update the following sections of your profile: 
					{{ profileBannerText }}
				</p>
				
			</div>
			
		</div>

        <div class="main-row profile">

            <!-- Settings nav section -->
            <div class="main-column profile">
                <div class="settings-nav-container">

                    <!-- Title of navbar -->
                    <div class="settings-title">
                        <h3>Settings</h3>
                    </div>

                    <!-- Column of links -->
                    <div class="settings-nav-links">

                        <!-- Account link -->
                        <button class="settings-nav-button" :class="{ highlighted: accountActive }" @click="accountNav"
                            role="link" aria-label="Link to Account">
                            Account
                        </button>

                        <!-- Skills link -->
                        <button class="settings-nav-button" :class="{ highlighted: skillsAndSpecActive }"
                            @click="skillsAndSpecNav" role="link" aria-label="Link to Skills and Specialisms">
                            {{ skillsButtonText }}
                        </button>

                        <!-- Interests Link -->
                        <button class="settings-nav-button" :class="{ highlighted: interestsActive }"
                            @click="interestsNav" role="link" aria-label="Link to Interests">
                            Interests
                        </button>

                        <!-- Preferences Link -->
                        <button class="settings-nav-button" :class="{ highlighted: preferencesActive }"
                            @click="preferencesNav" role="link" aria-label="Link to Preferences">
                            {{ preferencesButtonText }}
                        </button>

                    </div>

                </div>
            </div>

            <!-- Second panel - displaying the selected page -->
            <div class="main-column profile">

                <ProfileAccount v-if="accountActive" @profile-updated="showBanner" />

                <ProfileSkillsAndSpecialisms v-if="skillsAndSpecActive" @profile-updated="showBanner" />

                <ProfileInterests v-if="interestsActive" @profile-updated="showBanner" />

                <ProfilePreferences v-if="preferencesActive" @profile-updated="showBanner" />

            </div>
        </div>
    </div>
</template>

<script>
import ProfileAccount from '@/components/common/ProfileAccount.vue';
import ProfileInterests from '@/components/common/ProfileInterests.vue';
import ProfilePreferences from '@/components/common/ProfilePreferences.vue';
import ProfileSkillsAndSpecialisms from '@/components/common/ProfileSkillsAndSpecialisms.vue';
import axiosClient from '../../axiosClient';

export default {
    name: "create-account-page",
    components: {
        ProfileAccount,
        ProfileSkillsAndSpecialisms,
        ProfileInterests,
        ProfilePreferences
    },
    data() {
        return {
            accountActive: true,   // Flags to decide which view to display
            skillsAndSpecActive: false,
            interestsActive: false,
            preferencesActive: false,
            windowWidth: window.innerWidth,

            // Populate profile banner
			profileBanner: false,
			profileBannerText: "",
        }
    },
    computed: {
        // Display just "Skills" on smaller screens
        skillsButtonText() {
            return this.windowWidth <= 1175 ? "Skills" : "Skills & Specialisms";
        },
        // Display just "Prefs" on smaller screens
        preferencesButtonText() {
            return this.windowWidth <= 550 ? "Prefs" : "Preferences";
        },
    },
    mounted() {
        // Listen for window resize to update displayed text
        window.addEventListener("resize", this.handleResize);

        // Display prompt banner
		this.showBanner();
    },
    beforeUnmount() {
        // Remove listener on close
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        accountNav() {
            // Toggle off all but account page
            this.skillsAndSpecActive = false
            this.interestsActive = false
            this.preferencesActive = false

            // Toggle on nav page
            this.accountActive = true
        },
        skillsAndSpecNav() {
            // Toggle off all but skills page
            this.accountActive = false
            this.interestsActive = false
            this.preferencesActive = false

            // Toggle on skills page
            this.skillsAndSpecActive = true
        },
        interestsNav() {
            // Toggle off all but interests page
            this.accountActive = false
            this.skillsAndSpecActive = false
            this.preferencesActive = false

            // Toggle on interests page
            this.interestsActive = true
        },
        preferencesNav() {
            // Toggle off all but preferences page
            this.accountActive = false
            this.skillsAndSpecActive = false
            this.interestsActive = false

            // Toggle on preferences page
            this.preferencesActive = true
        },
        handleResize() {
            // Update the window width
            this.windowWidth = window.innerWidth;
        },
        async showBanner() {
			try {
				// Fetch empty fields
				const res = await axiosClient.get('/users/profile/prompt');
				const emptySections = res.data;
				
				// Fetch bool values from response
				const accountEmpty = emptySections.account;
				const skillsEmpty = emptySections.skills_and_specialisms;
				const certsEmpty = emptySections.certifications;
				const workInterestsEmpty = emptySections.work_interests;
				const personalInterestsEmpty = emptySections.personal_interests;
				
				// Set the text to the expected text if the flag is true
				const accountText = accountEmpty ? "Account" : ""
				const skillsText = skillsEmpty ? "Skills and Specialisms" : ""
				const certsText = certsEmpty ? "Certifications" : ""
				const workInterestsText = workInterestsEmpty ? "Work Interests" : ""
				const personalInterestsText = personalInterestsEmpty ? "Personal Interests" : ""
				
				// Combine empty field's names into a string
				const attributeNames = [accountText, skillsText, certsText, workInterestsText, personalInterestsText];
				const notNull = attributeNames.filter(attributeNames => attributeNames !== "");
				this.profileBannerText = notNull.join(", ");
				
				// If there's any empty fields, show the banner
				if (this.profileBannerText) {
					this.profileBanner = true;
				} else {
					this.profileBanner = false;
				}
				
				
			} catch (error) {
				console.error(error)
			}
		},
		closeBanner() {
			// Hide banner
			this.profileBanner = false;
		}

    }
}
</script>