<template>
    <!-- Community Connection Card -->
    <div class="connection-container">

        <!-- Connection Time -->
        <div class="connection-top">
            <ConnectionTime :time="connection.connection_date" />
        </div>

        <!-- Community Name and View Members Icon-->
        <div class="connection-name community">
            <p v-if="!membersToggle">{{ connection.community_name }} <i class="bi bi-person" @click="toggleMembers"
                    tabindex="0" @keydown="handleMembersKeyDown" role="button" aria-label="View Community Members" title="View Community Members"></i>
            </p>

            <!-- Colour in icon when toggled -->
            <p v-else-if="membersToggle">{{ connection.community_name }} <i class="bi bi-person-fill" tabindex="0"
                    @keydown="handleMembersKeyDown" @click="toggleMembers" role="button" aria-label="Hide Community Members" title="Hide Community Members"></i></p>

            <!-- Pronouns placeholder for consistent design -->
            <p v-if="!membersToggle">
                <span :class="{ 'has-pronouns': connection.pronouns }">
                    {{ connection.pronouns || 'None' }}
                </span>
            </p>
        </div>

        <!-- When toggle not pressed, display community info -->
        <div v-if="!membersToggle" class="connection-all-info">

            <!-- Community description -->
            <div class="connection-object-info">
                <p>{{ connection.description }}</p>
            </div>

            <!-- Connection Purpose / Connection Location -->
            <div class="connection-details">
                <p><span>Purpose: </span>{{ formattedCommunityPurpose }}</p>
                <p><span>Where: </span>{{ formattedConnectionLocation }}</p>
            </div>

        </div>

        <!-- When toggle pressed, display members -->
        <div v-else-if="membersToggle" class="connection-members" tabindex="0">
            <div class="office-section">
                <span class="bold-text">Common Office Days:</span>
                <div class="office-days" v-if="communityMembers.office">
                    <span :class="{ 'active-day': communityMembers.office.mon_office }" aria-label="Monday">M</span>
                    <span :class="{ 'active-day': communityMembers.office.tues_office }" aria-label="Tuesday">T</span>
                    <span :class="{ 'active-day': communityMembers.office.weds_office }" aria-label="Wednesday">W</span>
                    <span :class="{ 'active-day': communityMembers.office.thurs_office }" aria-label="Thursday">T</span>
                    <span :class="{ 'active-day': communityMembers.office.fri_office }" aria-label="Friday">F</span>
                </div>

            </div>

            <ul>
                <!-- Display owner first and highlight -->
                <li class="connection-owner" aria-label="Community Owner"> {{ communityMembers.owner }}</li>

                <!-- Iterate through members -->
                <li v-for="(member, index) in communityMembers.members" :key="index">
                    {{ member }}
                </li>
            </ul>
        </div>

        <!-- Meet Schedule -->
        <div v-if="!membersToggle" class="connection-bottom">
            <p>{{ connection.meet_schedule }}</p>
        </div>

        <!-- Say Thanks / Schedule Meet buttons -->
        <ConnectionButtons :connection="connection" />
    </div>

</template>

<script>
import ConnectionTime from './ConnectionTime.vue';
import axiosClient from '../../../axiosClient';
import ConnectionButtons from './ConnectionButtons.vue';

export default {
    name: "CommunityConnection",
    data() {
        return {
            membersToggle: false,
            communityMembers: [],
            owner: ""
        }
    },
    props: {
        connection: {
            type: Object,
            required: true,
        },
    },
    components: {
        ConnectionTime,
        ConnectionButtons
    },
    computed: {
        formattedCommunityPurpose() {
            // Removes underscore from connection.purpose, returns "Not Found" if not available
            return this.connection?.purpose
                ? this.connection.purpose.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedConnectionLocation() {
            // Removes underscore from connection.meet_location, returns "Not Found" if not available
            return this.connection?.meet_location
                ? this.connection.meet_location.replace(/_/g, ' ')
                : "Not Found";
        }

    },
    methods: {
        async toggleMembers() {
            // Flip value of bool
            this.membersToggle = !this.membersToggle;

            // When true
            if (this.membersToggle) {
                try {

                    // Fetch community members
                    const response = await axiosClient.get(`/connect/community_members/${this.connection.community_name}`,);
                    this.communityMembers = response.data;

                    console.log(this.communityMembers)

                } catch (error) {

                    console.error("Failed to fetch community members", error);
                    // Reset members on error
                    this.communityMembers = [];

                }
            }
        },
        handleMembersKeyDown(event) {
            // Handle pressing enter on the members icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleMembers();
                event.preventDefault();
            }
        },
    }
}
</script>