<template>
    <div class="main-page-layout">

        <div class="main-row">

            <!-- Create Community on left side of scren / top -->
            <div class="main-column">
                <CreateCommunityContainer @community-created="handleCreate" @new-connection="handleConnection" />
            </div>

            <!-- Manage Communities listed on right side of screen / below -->
            <div class="main-column">
                <ManageCommunitiesContainer ref="refreshCommunities" />
            </div>

        </div>
        
    </div>
</template>

<script>
import CreateCommunityContainer from '@/components/common/CreateCommunityContainer.vue';
import ManageCommunitiesContainer from '@/components/common/ManageCommunitiesContainer.vue';

export default {
    name: "manage-communities-page",
    components: {
        ManageCommunitiesContainer,
        CreateCommunityContainer
    },
    methods: {
        handleCreate() {
            // Call refresh owned communities 
            this.$refs.refreshCommunities.fetchOwnedCommunities();
        },
        handleConnection() {
            // Call refresh joined communities 
            this.$refs.refreshCommunities.fetchJoinedCommunities();
        },

    }
}
</script>
