<template>
    <!-- Similar Community Card -->
    <div class="connection-container">

        <!-- Connection Top -->
        <div class="connection-top">
            <p>You Might Like...</p>
        </div>

        <!-- Community Name and View Members Icon-->
        <div class="connection-name community">
            <p v-if="!membersToggle">{{ connection.community.community_name }} <i class="bi bi-person" tabindex="0"
                    @keydown="handleMembersKeyDown" @click="toggleMembers" role="button"
                    aria-label="View Community Members" title="View Community Members"></i>
            </p>

            <!-- Colour in icon when toggled -->
            <p v-else-if="membersToggle">{{ connection.community.community_name }} <i class="bi bi-person-fill"
                    tabindex="0" @keydown="handleMembersKeyDown" @click="toggleMembers" role="button"
                    aria-label="Hide Community Members" title="Hide Community Members"></i></p>

            <!-- Pronouns placeholder for consistent design -->
            <p v-if="!membersToggle">
                <span :class="{ 'has-pronouns': connection.pronouns }">
                    {{ connection.pronouns || 'None' }}
                </span>
            </p>
        </div>

        <!-- When toggle not pressed, display community info -->
        <div v-if="!membersToggle" class="connection-all-info">

            <!-- Community description -->
            <div class="connection-object-info">
                <p>{{ connection.community.description }}</p>
            </div>

            <!-- Community Purpose / Community Location -->
            <div class="connection-details">
                <p><span>Purpose: </span>{{ formattedCommunityPurpose }}</p>
                <p><span>Where: </span>{{ formattedConnectionLocation }}</p>
            </div>

        </div>

        <!-- When toggle pressed, display members -->
        <div v-else-if="membersToggle" class="connection-members" tabindex="0">
            <div class="office-section">
                <span class="bold-text">Common Office Days:</span>
                <div class="office-days" v-if="connection.office">
                    <span :class="{ 'active-day': connection.office.mon_office }" aria-label="Monday">M</span>
                    <span :class="{ 'active-day': connection.office.tues_office }" aria-label="Tuesday">T</span>
                    <span :class="{ 'active-day': connection.office.weds_office }" aria-label="Wednesday">W</span>
                    <span :class="{ 'active-day': connection.office.thurs_office }" aria-label="Thursday">T</span>
                    <span :class="{ 'active-day': connection.office.fri_office }" aria-label="Friday">F</span>
                </div>

            </div>

            <ul>
                <!-- Display owner first and highlight -->
                <li class="connection-owner" aria-label="Community Owner"> {{ connection.owner }}</li>

                <!-- Iterate through members -->
                <li v-for="member in connection.connections" :key="member" class="community-member-connection">
                    {{ member }}
                </li>
                <li v-for="(member, index) in connection.members" :key="index">
                    {{ member }}
                </li>
            </ul>
        </div>

        <!-- Meet Schedule -->
        <div v-if="!membersToggle" class="connection-bottom">
            <p>{{ connection.community.meet_schedule }}</p>
        </div>

        <!-- Connect button -->
        <div class="connection-buttons">
            <button class="similar-communities-connect" :class="{ 'connected-button': connection.connected }"
                :disabled="connection.connected" @click="connect">
                {{ connection.connected ? 'Connected' : 'Connect' }}
            </button>
        </div>
    </div>

</template>

<script>
import ConnectionTime from './ConnectionTime.vue';
import axiosClient from '../../../axiosClient';
import ConnectionButtons from './ConnectionButtons.vue';

export default {
    name: "SimilarCommunityConnection",
    data() {
        return {
            membersToggle: false,
            communityMembers: [],
            owner: ""
        }
    },
    props: {
        connection: {
            type: Object,
            required: true,
        },
    },
    components: {
        ConnectionTime,
        ConnectionButtons
    },
    computed: {
        formattedCommunityPurpose() {
            // Removes underscore from connection.community.purpose, returns "Not Found" if not available
            return this.connection?.community.purpose
                ? this.connection.community.purpose.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedConnectionLocation() {
            // Removes underscore from connection.community.meet_location, returns "Not Found" if not available
            return this.connection?.community.meet_location
                ? this.connection.community.meet_location.replace(/_/g, ' ')
                : "Not Found";
        }

    },
    methods: {
        async toggleMembers() {
            // Check if it is not an information message card
            if (this.connection.community.id) {

                // Flip value of bool
                this.membersToggle = !this.membersToggle;

            } else {
                return
            }
        },
        async connect() {
            // Check if it is not an information message card
            if (this.connection.community.id) {

                // Connected
                this.connection.connected = true

                try {

                    // Create a community connection
                    const requestData = {
                        community_id: this.connection.community.id
                    }

                    // Create connection
                    await axiosClient.post("/connect/community", requestData);

                    // Trigger refresh in joined communities
                    this.$emit("new-connection")

                } catch (error) {
                    console.error(error)
                }

            } else {
                return
            }
        },
        handleMembersKeyDown(event) {
            // Handle pressing enter on the members icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleMembers();
                event.preventDefault();
            }
        },
    }
}
</script>